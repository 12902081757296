import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";



export const EditButton = styled(IconButton)({
    backgroundColor: "#E5F3FF",
    fontSize:17,
    color: "#267DC9",
    marginLeft: "8px", 
    "&:hover": {
      backgroundColor: "#F4FBFF",
    },
  });
  