import types from '../actions-types'
import produce from 'immer'
import helpers from 'helpers'
import initialState from '../initialState'

export default function receiversRducer(state = initialState, action) {
	const { type, payload } = action

	return produce(state, draft => {
		switch (type) {
			case types.selectedSet:
				if (!helpers.validator.isEmptyString(payload?.id))
					draft.selected = payload.id
				else draft.selected = null
				break

			case types.merged:
				if (
					helpers.validator.isObject(payload?.receivers) &&
					!helpers.validator.isEmptyObject(payload?.receivers)
				)
					Object.entries(payload.receivers).forEach(([id, receiver]) => {
						if (!helpers.validator.isEmptyString(id))
							draft.all[id] = receiver
					})
				break

			case types.removed:
				if (!helpers.validator.isEmptyString(payload?.id)) {
					delete draft.all[payload.id]
					if (draft.selected === payload.id) draft.selected = null
				}
				break

			case types.statusChanged:
				if (
					helpers.validator.isObject(payload?.receiver) &&
					!helpers.validator.isEmptyObject(payload?.receiver) &&
					!helpers.validator.isEmptyString(payload?.receiver?.id)
				) {
					draft.all[payload.receiver.id] = {...draft.all[payload.receiver.id], status: payload.receiver.status}
					draft.selected = payload.receiver.id
				}
				break

			case types.filterUpdated:
				draft.filter = { ...draft.filter, ...payload }
				break

			default:
				break
		}
	})
}
