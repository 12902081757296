import { memoize } from 'proxy-memoize'
import selectOneById from '../oneById'
import helpers from 'helpers'
import moment from 'moment'

const detailedOneById = memoize(({ state, id }) => {
	const client = selectOneById({ state, id })

	if (
		!helpers.validator.isObject(client) ||
		helpers.validator.isEmptyObject(client)
	)
		return null

	let result = {
		...client,
		nationality: helpers.getStatus([client.nationality]),
		fullName: `${client.firstName ?? ''}  ${client.lastName ?? ''}`,
		balance: `${client.balance ?? ''}  ${client.currency ?? ''}`,
		birthday: helpers.dateTime.getDate(client.birthday),
		lastLoginAt: `${helpers.dateTime.getDate(
			client.lastLoginAt
		)}  ${helpers.dateTime.getTime(client.lastLoginAt)}`,
		createdAt: `${helpers.dateTime.getDate(
			client.addedAt
		)},  ${helpers.dateTime.getTime(client.addedAt)}`,
	}

	// if (
	// 	helpers.validator.isArray(client.identity) &&
	// 	!helpers.validator.isEmptyArray(client.identity)
	// ) {
	// 	const lastUploadedIdentity = client.identity[0]

		if (helpers.validator.isObject(client.identity)) {
			result = {
				...result,
				identity: {
					...client.identity,
					values:
						!client.identity.front && !client.identity.back
							? null
							: [client.identity.front, client.identity.back],
					isExpired: moment(client.identity.expiresAt).isAfter(Date.now),
					isExist: client.identity.front || client.identity.back,
					isAccepted: client.identity.isAccepted,
				},
			}
			result = {
				...result,
				identity: {
					...result.identity,
					isRejected:
						result.identity.isExist &&
						!result.identity.isAccepted &&
						!helpers.validator.isEmptyString(result.identity.rejected),
				},
			}
			result = {
				...result,
				identity: {
					...result.identity,
					reason: result.identity.isRejected
						? result.identity.rejected ?? null
						: null,
					isInreview:
						result.identity.isExist &&
						!result.identity.isAccepted &&
						!result.identity.isRejected &&
						!result.identity.isExpired,
				},
			}
		}
	//}

	// if (
	// 	helpers.validator.isArray(client.profile) &&
	// 	!helpers.validator.isEmptyArray(client.profile)
	// ) {
	// 	const lastUploadedProfile = client.profile[0]

		if (helpers.validator.isObject(client.profile)) {
			result = {
				...result,
				profile: {
					photo: client.profile.value,
					isAccepted: client.profile.isAccepted,
					isRejected:
					client.profile.value &&
						!client.profile.isAccepted &&
						!helpers.validator.isEmptyString(client.profile.rejected),
				},
			}
			result = {
				...result,
				profile: {
					...result.profile,
					reason: result.profile.isRejected
						? client.profile?.rejected ?? null
						: null,
					isInreview:
						result.profile.photo &&
						!result.profile.isAccepted &&
						!result.profile.isRejected,
				},
			}
		}
	//}

	// if (
	// 	helpers.validator.isArray(client.phone) &&
	// 	!helpers.validator.isEmptyArray(client.phone)
	// ) {
	// 	const lastPhone = client.phone[0]

		if (helpers.validator.isObject(client.phone)) {
			result = {
				...result,
				phone: {
					value: client.phone.value,
					isVerified: !helpers.validator.isEmptyString(client.phone.verifiedAt),
				},
			}
		}
	//}

	// if (
	// 	helpers.validator.isArray(client.email) &&
	// 	!helpers.validator.isEmptyArray(client.email)
	// ) {
	// 	const lastEmail = client.email[0]

		if (helpers.validator.isObject(client.email)) {
			result = {
				...result,
				email: {
					value: client.email.value,
					isVerified: !helpers.validator.isEmptyString(client.email.verifiedAt),
				},
			}
		}
	//}

	result = {
		...result,
		account: {
			isActive: helpers.getStatus(client.status) === 'active',
			isVerified:
				result.email?.isVerified &&
				result.phone?.isVerified &&
				result.profile?.isAccepted &&
				result.identity?.isAccepted,
			isInreview: result.identity?.isInreview || result.profile?.isInreview,
		},
	}

	return result
})

export default detailedOneById
