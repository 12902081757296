import { Grid, Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useState } from 'react'

import Delete from '../delete'
import Reject from '../reject'
import Card from '../card'

import selectors from '../../selectors'
import actions from '../../actions'

const initialState = {
	openDelete: false,
	openReject: false,
}

export default function ExpeditionMain() {
	const dispatch = useDispatch()

	const [modal, setModal] = useState(initialState)

	const expeditionsIds = useSelector(selectors.filteredIds)

	// get expeditions' ids
	useEffect(() => {
		dispatch(actions.get())
	}, [])

	const handleModalOpen = useCallback(
		({ type, id }) => {
			dispatch(actions.selectedSet({ id }))

			if (type === 'delete') setModal({ ...initialState, openDelete: true })
			else if (type === 'reject')
				setModal({ ...initialState, openReject: true })
		},
		[initialState, dispatch]
	)

	const handleModalClose = useCallback(
		() => setModal(initialState),
		[initialState]
	)

	return (
		<>
			{expeditionsIds.map(expeditionId => (
				<Grid
					key={expeditionId}
					xs={3}
					style={{ marginTop: 10 }}
					container
					justifyContent='center'
					alignItems='center'
				>
					<Card id={expeditionId} handleOpen={handleModalOpen} />
				</Grid>
			))}
			<Delete open={modal.openDelete} handleCancel={handleModalClose} />
			<Reject open={modal.openReject} handleCancel={handleModalClose} />
		</>
	)
}
