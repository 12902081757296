import { put, select } from "redux-saga/effects";

import helpers from "helpers";
import config from "../../../../config.json";
import errors from "store/errors";
import clientsActions from "../../actions";
import pathsActions from "../../actions";
import normalizer from "../../normalizer";
import pathsSelectors from "../../selectors"
import { batchActions } from "redux-batched-actions";

export default function* changeStatus({ payload = {}, meta = {} }) {
  let actions = [];

  const pathId = yield select(pathsSelectors.selected);

  let { data } = yield helpers.sagas.worker({
    method: "POST",
    url: config.endpoints.paths.changeStatus,
    data: {...payload, id: payload.id ?? pathId },
    loadingId: meta.id,
  });

  if (data?.success && data?.updated) {
    if(helpers.getStatus(data?.updated?.status) === "deleted")
      actions.push(pathsActions.removed({ id: data.updated.id }));
    else {
      const normalized = normalizer([data.updated]);

      console.log("normalized paths", normalized)

      actions.push(clientsActions.merged({ clients: normalized.users }));
      actions.push(pathsActions.merged({ paths: normalized.paths }));
    }

    actions.push(errors.actions.updated({ isSuccess: true, id: meta.id }));

    yield put(batchActions(actions));
  }
}