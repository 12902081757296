import { useCallback, useState } from 'react'
import UpMenu from './up-menu'
import { Outlet } from 'react-router-dom'
import Filter from './filter'
import { Box, Grid } from '@mui/material'

export default function Dashboard() {
	const [showFilter, setShowFilter] = useState(false)

	const handleOpenFilter = useCallback(() => setShowFilter(true), [])
	const handleCloseFilter = useCallback(() => setShowFilter(false), [])

	return (
		<>
			<UpMenu showFilter={handleOpenFilter} />
			<Box sx={{ flexGrow: 1 }}>
				<Grid container style={{ paddingLef: 5, paddingRight: 5 }}>
					<Outlet />
				</Grid>
			</Box>
			<Filter show={showFilter} close={handleCloseFilter} />
		</>
	)
}
