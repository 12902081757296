import { all } from "redux-saga/effects";

import get from "./get";
import getOne from "./getOne";
import getMany from "./getMany";
import remove from "./remove";
import changeStatus from "./changeStatus";
import hardDelete from "./hardDelete";

export default function* receiverSaga() {
  yield all([get(), getOne(), remove(), changeStatus(), hardDelete, getMany()]);
}
