import types from "../actionsTypes";
import { produce } from "immer";
import helpers from "helpers";
import initialState from "../initialState"

const expeditionsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  return produce(state, (draft) => {
    switch (type) {
      case types.merged:
        if (
          helpers.validator.isObject(payload?.expeditions) &&
          !helpers.validator.isEmptyObject(payload?.expeditions)
        )
          Object.entries(payload.expeditions).forEach(([id, expedition]) => {
            if (
              !helpers.validator.isEmptyObject(expedition) &&
              !helpers.validator.isEmptyString(id)
            )
              draft.all[id] = expedition;
          });
        break;

      case types.updated:
        if (
          helpers.validator.isObject(payload?.expedition) &&
          !helpers.validator.isEmptyObject(payload?.expedition) &&
          !helpers.validator.isEmptyString(payload?.expedition?.id)
        )
          draft.all[payload.expedition.id] = payload.expedition;

        break;

      case types.selectedSet:
        draft.selected = payload?.id;
        break;

      case types.filterUpdated:
        draft.filter = { ...draft.filter, ...payload };
        break;

      case types.removed:
          if (!helpers.validator.isEmptyString(payload?.id)) {
            delete draft.all[payload.id];
            if (draft.selected === payload.id) draft.selected = null;
          }
      break;

      default:
        break;
    }
  });
};

export default expeditionsReducer;
