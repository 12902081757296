import { combineReducers } from "redux";

import errors from "./errors";
import loading from "./loading";

import features from "../features";

const rootReducer = combineReducers({
  errors: errors.reducer,
  loading: loading.reducer,
  auth: features.auth.reducer,
  paths: features.paths.reducer,
  expeditions: features.expeditions.reducer,
  clients: features.clients.reducer,
  receivers: features.receivers.reducer,
  payments: features.payments.reducer,
});

export default rootReducer;
