import Components from "components";
import { useCallback, useState } from "react";
import Reject from "./reject";
import Accept from "./accept";

export default function ProfileDetailsMain({ open, handleClose }) {
  const [activeAction, setActiveAction] = useState("accept");

  const navigateToReject = useCallback(() => setActiveAction("reject"));
  const navigateToAccept = useCallback(() => setActiveAction("accept"));

  const handleCancel = useCallback(() => {
    setActiveAction("accept");
    handleClose();
  }, []);

  return (
    <Components.Modal
      open={open}
      handleClose={handleCancel}
      renderContent={
        activeAction === "accept" ? (
          <Accept handleReject={navigateToReject} handleCancel={handleCancel} />
        ) : (
          <Reject handleBack={navigateToAccept} handleCancel={handleCancel} />
        )
      }
    />
  );
}
