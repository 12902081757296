import { memoize } from "proxy-memoize";
import selectAll from "../all";
import helpers from "helpers";
import clientsSelector from "features/clients/selectors";

const detailedOneById = memoize(({ state, id }) => {
  const expeditions = selectAll(state);

  const expedition = expeditions[id];
  if (
    !helpers.validator.isObject(expedition) ||
    helpers.validator.isEmptyObject(expedition)
  )
    return null;

  const client = clientsSelector.detailedOneById({
    state,
    id: expedition?.creatorId,
  });
  if (!client) return null;

  const status = helpers.getStatus(expedition.status)

  let result = {
    creator: {
      profile: client.profile?.photo,
      isVerified: client.isVerified,
      id: expedition?.creatorId,
    },
    startedAt: `${helpers.dateTime.getDate(
      expedition.startedAt
    )},  ${helpers.dateTime.getTime(expedition.startedAt)}`,
    from: expedition.startAddress?.city,
    to: expedition.endAddress?.city,
    price: expedition?.price,
    currency: expedition?.currency,
    receiver: expedition.receiver,
    size: expedition?.parcel?.size,
    weight: expedition?.parcel?.weight,
    photos: expedition?.parcel?.photos,
    status,
    isApproved: status === "approved",
    isDeleted: status === "deleted",
    isRejected: status === "rejected",
  };

  return result;
});

export default detailedOneById;
