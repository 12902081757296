import types from "../actions-types";
import produce from "immer";
import initialState from '../initialState'

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;

  return produce(state, (draft) => {
    switch (type) {
      case types.userSet:
        draft.user = payload.user;
        break;
      case types.tokenSet:
        draft.token = payload.token;
        break;
      case types.logout:
        draft.token = null;
        draft.user = {};
        break;
      default:
        break;
    }
  });
};

export default authReducer;
