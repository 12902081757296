/**
 * @type simple
 * @description selects selected receiver's info
 * @params state
 * @returns Object | null
 * @detailed_return  name: full name, profile, type: dim3akUser | notDim3akUser
 */

import { memoize } from "proxy-memoize";
import selectSelected from "../selected";
import selectDetailedOneById from "../detailed-one-by-id";

export default memoize((state) => {
  const selectedReceiverId = selectSelected(state);

  return selectDetailedOneById({ state, id: selectedReceiverId });
});
