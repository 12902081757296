/**
 * @type simple
 * @description selects receivers' search filter
 * @params state
 * @returns Object | null
 * @detailed_return filter
 */

import { memoize } from "proxy-memoize";

export default memoize((state) => state?.receivers?.filter ?? null);
