import Card from "../card";
import { Grid, Box } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import actions from "../../actions";
import selectors from "../../selectors";
import Delete from "../delete";
import Deactivate from "../deactivate";
import NothingFound from "../nothingFound";


const initialState = {
  openDelete: false,
  openDeacivate: false,
};

export default function ReceiversMain() {
  const dispatch = useDispatch();

  const [modal, setModal] = useState(initialState);
  const receiversIds = useSelector(selectors.filteredIds);

  // get receivers' ids
  useEffect(() => {
    dispatch(actions.get());
  }, []);

  const handleModalOpen = useCallback(
    ({ type, id }) => {
      dispatch(actions.selectedSet({ id }));

      if (type === "delete") setModal({ ...initialState, openDelete: true });
      else if (type === "deactivate")
        setModal({ ...initialState, openDeacivate: true });
    },
    [initialState, dispatch]
  );

  const handleModalClose = useCallback(
    () => setModal(initialState),
    [initialState]
  );

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        {receiversIds?.length ? (
          <Grid container style={{ paddingLef: 5, paddingRight: 5 }}>
            {receiversIds.map((receiverId) => (
              <Grid
                key={receiverId}
                xs={3}
                style={{ marginTop: 10 }}
                container
                justifyContent="center"
                alignItems="center"
              >
                <Card id={receiverId} handleOpen={handleModalOpen} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <NothingFound />
        )}
      </Box>
      <Delete open={modal.openDelete} handleCancel={handleModalClose} />
      <Deactivate open={modal.openDeacivate} handleCancel={handleModalClose} />
    </>
  );
}
