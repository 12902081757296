export default {
	0: {
		id: '0',
		addedAt: '2022-11-23T18:45:13.977Z',
		username: 'hlachqar',
		firstName: 'HAMZA',
		lastName: 'LACHQAR',
		birthday: '1996-11-23T19:31:14.932Z',
		nationality: [{ value: 'Morocco', date: '2023-01-22T14:43:31.123Z' }],
		isConditionsAccepted: true,
		isSubscribedToNews: false,
		currency: 'MAD',
		balance: 0,
		language: 'en',
		gender: 'M',
		lastLoginAt: '2023-01-22T14:43:31.123Z',
		email: [
			{
				value: 'lachquarhamza@gmail.com',
				date: '2023-01-22T14:43:31.123Z',
				verifiedAt: '',
			},
		],
		phone: [
			{
				value: '+212655093497',
				date: '2023-01-22T14:43:31.123Z',
				verifiedAt: '',
			},
		],
		profile: [
			{
				value: 'https://picsum.photos/600/600',
				isAccepted: false,
				rejected: 'Sunglasses not allowed in profile photos',
				date: '2023-01-22T14:43:31.123Z',
				updatedAt: '2023-01-22T14:43:31.123Z',
				user: '1',
			},
		],
		status: [
			{
				value: 'inactive',
				date: '2023-01-22T05:33:23.050Z',
				user: '1',
			},
			{
				value: 'active',
				date: '2023-01-22T05:33:23.050Z',
				user: '1',
			},
		],
		identity: [
			{
				type: 'cin',
				back: 'https://picsum.photos/600/600',
				front: 'https://picsum.photos/600/600',
				expiresAt: '2023-01-22T05:33:23.050Z',
				code: 'Y456789',
				isAccepted: false,
				rejected: 'Unvalid identity card provided',
				date: '2023-01-22T14:43:31.123Z',
				updatedAt: '2023-01-22T14:43:31.123Z',
				user: '1',
			},
		],
	},

	1: {
		id: '1',
		addedAt: '2022-11-23T18:45:13.977Z',
		username: 'hlachqar',
		firstName: 'HAMZA',
		lastName: 'LACHQAR',
		birthday: '1996-11-23T19:31:14.932Z',
		nationality: [{ value: 'Morocco', date: '2023-01-22T14:43:31.123Z' }],
		isConditionsAccepted: true,
		isSubscribedToNews: false,
		currency: 'MAD',
		balance: 120,
		language: 'en',
		gender: 'M',
		lastLoginAt: '2023-01-22T14:43:31.123Z',
		email: [
			{
				value: 'lachquarhamza@gmail.com',
				date: '2023-01-22T14:43:31.123Z',
				verifiedAt: '',
			},
		],
		phone: [
			{
				value: '+212655093497',
				date: '2023-01-22T14:43:31.123Z',
				verifiedAt: '',
			},
		],
		profile: [
			{
				value: 'https://picsum.photos/600/600',
				isAccepted: true,
				rejected: '',
				date: '2023-01-22T14:43:31.123Z',
				updatedAt: '2023-01-22T14:43:31.123Z',
			},
		],
		status: [
			{
				value: 'active',
				date: '2023-01-22T05:33:23.050Z',
				user: '1',
			},
			{
				value: 'inactive',
				date: '2023-01-22T05:33:23.050Z',
				user: '1',
			},
		],
		identity: [
			{
				type: 'cin',
				back: 'https://picsum.photos/600/600',
				front: 'https://picsum.photos/600/600',
				expiresAt: '2023-01-22T05:33:23.050Z',
				code: 'Y456789',
				isAccepted: false,
				rejected: 'Some reject message here',
				date: '2023-01-22T14:43:31.123Z',
				updatedAt: '2023-01-22T14:43:31.123Z',
			},
		],
	},

	2: {
		id: '02',
		addedAt: '2022-11-23T18:45:13.977Z',
		username: 'hlachqar',
		firstName: 'HAMZA',
		lastName: 'LACHQAR',
		birthday: '1996-11-23T19:31:14.932Z',
		nationality: [{ value: 'Morocco', date: '2023-01-22T14:43:31.123Z' }],
		isConditionsAccepted: true,
		isSubscribedToNews: false,
		currency: 'MAD',
		balance: 0,
		language: 'en',
		gender: 'M',
		lastLoginAt: '2023-01-22T14:43:31.123Z',
		email: [
			{
				value: 'lachquarhamza@gmail.com',
				date: '2023-01-22T14:43:31.123Z',
				verifiedAt: '',
			},
		],
		phone: [
			{
				value: '+212655093497',
				date: '2023-01-22T14:43:31.123Z',
				verifiedAt: '',
			},
		],
		profile: [
			{
				value: 'https://picsum.photos/600/600',
				isAccepted: false,
				rejected: 'Sunglasses not allowed in profile photos',
				date: '2023-01-22T14:43:31.123Z',
				updatedAt: '2023-01-22T14:43:31.123Z',
			},
		],
		status: [
			{
				value: 'inactive',
				date: '2023-01-22T05:33:23.050Z',
				user: '1',
			},
			{
				value: 'active',
				date: '2023-01-22T05:33:23.050Z',
				user: '1',
			},
		],
		identity: [
			{
				type: 'cin',
				back: 'https://picsum.photos/600/600',
				front: 'https://picsum.photos/600/600',
				expiresAt: '2023-01-22T05:33:23.050Z',
				code: 'Y456789',
				isAccepted: true,
				rejected: 'Unvalid identity card provided',
				date: '2023-01-22T14:43:31.123Z',
				updatedAt: '2023-01-22T14:43:31.123Z',
			},
		],
	},

	3: {
		id: '3',
		addedAt: '2022-11-23T18:45:13.977Z',
		username: 'hlachqar',
		firstName: 'HAMZA',
		lastName: 'LACHQAR',
		birthday: '1996-11-23T19:31:14.932Z',
		nationality: [{ value: 'Morocco', date: '2023-01-22T14:43:31.123Z' }],
		isConditionsAccepted: true,
		isSubscribedToNews: false,
		currency: 'MAD',
		balance: 0,
		language: 'en',
		gender: 'M',
		lastLoginAt: '2023-01-22T14:43:31.123Z',
		email: [
			{
				value: 'lachquarhamza@gmail.com',
				date: '2023-01-22T14:43:31.123Z',
				verifiedAt: '',
			},
		],
		phone: [
			{
				value: '+212655093497',
				date: '2023-01-22T14:43:31.123Z',
				verifiedAt: '',
			},
		],
		profile: [
			{
				value: 'https://picsum.photos/600/600',
				isAccepted: false,
				rejected: 'Sunglasses not allowed in profile photos',
				date: '2023-01-22T14:43:31.123Z',
				updatedAt: '2023-01-22T14:43:31.123Z',
			},
		],
		status: [
			{
				value: 'inactive',
				date: '2023-01-22T05:33:23.050Z',
				user: '1',
			},
			{
				value: 'active',
				date: '2023-01-22T05:33:23.050Z',
				user: '1',
			},
		],
		identity: [
			{
				type: 'cin',
				back: 'https://picsum.photos/600/600',
				front: 'https://picsum.photos/600/600',
				expiresAt: '2023-01-22T05:33:23.050Z',
				code: 'Y456789',
				isAccepted: true,
				rejected: 'Unvalid identity card provided',
				date: '2023-01-22T14:43:31.123Z',
				updatedAt: '2023-01-22T14:43:31.123Z',
			},
		],
	},
}
