import { put, select, call } from "redux-saga/effects";

import helpers from "helpers";
import config from "../../../../config.json";
import errors from "store/errors";
import receiverActions from "../../actions";
import receiverSelectors from "../../selectors";
import normalizer from "../../normalizer";
import { batchActions } from "redux-batched-actions";
import utils from "../../utils";
import clientsActions from "features/clients/actions"

export default function* getManyWorker({ meta = {} }) {
  const params = yield select(receiverSelectors.filter);

  let { data } = yield helpers.sagas.worker({
    url: config.endpoints.receivers.root,
    params,
    loadingId: meta.id,
  });

  let actions = [];

  if (data?.success) {
    if (data?.found?.length) {
        const notMembers = yield call(utils.filterNotMembers, data.found);

        const { receivers, clients } = normalizer(notMembers);
        
        console.log("clients:", clients)
        console.log("receivers:", receivers)

        actions.push(receiverActions.merged({ receivers }));
        actions.push(clientsActions.merged({ clients }));
    }

    actions.push(errors.actions.updated({ isSuccess: true, id: meta.id }));
    yield put(batchActions(actions));
  }
}
