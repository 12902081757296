import types from "../../actions-types";

export default function login({ email, username, password, meta = {} }) {
  return {
    type: types.login,
    payload: {
      email,
      password,
      username,
    },
    meta,
  };
}
