import types from "../../actionsTypes";

export default function getMany({
  status,
  identityStatus,
  phoneStatus,
  profileStatus,
  emailstatus,
  identityType,
  meta = {},
} = {}) {
  return {
    type: types.getMany,
    payload: {
      status,
      identityStatus,
      phoneStatus,
      profileStatus,
      emailstatus,
      identityType
    },
    meta: {id: types.getMany, ...meta},
  };
}
