import types from "../../actionsTypes";

export default function changeIdentityStatus({
  status,
  identityNumber,
  experationDate,
  reason,
  meta = {}
}) {
  return {
    type: types.changeIdentityStatus,
    payload: { status, identityNumber, experationDate, reason },
    meta: { id: types.changeIdentityStatus, ...meta },
  };
}
