import Card from '../card'
import { Grid } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import actions from '../../actions'
import selectors from '../../selectors'
import Profile from '../profile'
import Identity from '../identity'
import Delete from '../delete'
import Deactivate from '../deactivate'

const initialState = {
	openProfile: false,
	openIdentity: false,
	openDelete: false,
	openDeacivate: false,
}

export default function ClientMain() {
	const dispatch = useDispatch()

	const [modal, setModal] = useState(initialState)
	const clients = useSelector(selectors.filteredIds)

	// get clients' ids
	useEffect(() => {
		dispatch(actions.get())
	}, [])

	const handleModalOpen = useCallback(
		({ type, id, open }) => {
			dispatch(actions.selectedSet({ id }))

			if (type === 'identity')
				setModal({ ...initialState, openIdentity: true && open })
			else if (type === 'profile')
				setModal({ ...initialState, openProfile: true && open })
			else if (type === 'delete')
				setModal({ ...initialState, openDelete: true })
			else if (type === 'deactivate')
				setModal({ ...initialState, openDeacivate: true })
		},
		[initialState, dispatch]
	)

	const handleModalClose = useCallback(
		() => setModal(initialState),
		[initialState]
	)

	return (
		<>
			{clients.map(client => (
				<Grid
					key={client}
					xs={3}
					style={{ marginTop: 10 }}
					container
					justifyContent='center'
					alignItems='center'
				>
					<Card id={client} handleOpen={handleModalOpen} />
				</Grid>
			))}

			<Profile open={modal.openProfile} handleClose={handleModalClose} />
			<Identity open={modal.openIdentity} handleClose={handleModalClose} />
			<Delete open={modal.openDelete} handleCancel={handleModalClose} />
			<Deactivate open={modal.openDeacivate} handleCancel={handleModalClose} />
		</>
	)
}
