import filteredIds from "./filtered-ids";
import detailedOneById from "./detailed-one-by-id";
import detailedSelected from "./detailed-selected";
import filter from "./filter";
import selected from "./selected";

export default Object.freeze({
  filteredIds,
  detailedOneById,
  detailedSelected,
  filter,
  selected,
});
