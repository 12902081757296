import getMany from "./getMany";
import changeStatus from "./changeStatus";
import filterUpdated from "./filterUpdated";
import merged from "./merged";
import selectedSet from "./selectedSet";
import updated from "./updated";
import changeIdentityStatus from "./changeIdentityStatus";
import changeProfileStatus from "./changeProfilestatus";
import getOne from "./getOne";
import get from "./get";
import remove from "./remove";
import hardDelete from "./hardDelete";
import removed from "./removed";
import update from "./update";

export default Object.freeze({
  get,
  getMany,
  getOne,
  changeStatus,
  filterUpdated,
  merged,
  selectedSet,
  updated,
  changeProfileStatus,
  changeIdentityStatus,
  remove,
  hardDelete,
  removed,
  update
});
