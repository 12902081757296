import { normalize, schema } from "normalizr";

// user schema
const user = new schema.Entity("users");

// receiver schema
const receiver = new schema.Entity("receivers", {
  userId: user,
});

// expedition schema
const expedition = new schema.Entity("expeditions", {
  creatorId: user,
  receiver: receiver,
});

const expeditionsListSchema = new schema.Array(expedition);

export default function normalizer(data) {
  const normalizedData = normalize(data, expeditionsListSchema);

  return {
    expeditions: normalizedData.entities["expeditions"],
    users: normalizedData.entities["users"],
    receivers: normalizedData.entities["receivers"],
  };
}
