import login from "./login";
import checkEmail from "./check-email";
import checkCode from "./check-code";
import resetPassword from "./reset-password";
import userSet from "./user-set";
import tokenSet from "./token-set";
import logout from "./logout";
import setup from "./setup";

export default Object.freeze({
  login,
  checkEmail,
  checkCode,
  resetPassword,
  userSet,
  tokenSet,
  logout,
  setup,
});
