import { put, select } from "redux-saga/effects";

import helpers from "helpers";
import config from "../../../../config.json";
import errors from "store/errors";
import receiverActions from "../../actions";
import receiverSelectors from "../../selectors";
import { batchActions } from "redux-batched-actions";

export default function* getOneWorker({ meta = {} }) {
  const params = yield select(receiverSelectors.filter);

  let { data } = yield helpers.sagas.worker({
    url: config.endpoints.receivers.root,
    params,
    loadingId: meta.id,
  });

  let actions = [];

  if (data?.success) {
    if (data?.found && !data?.found?.userId) {
        actions.push(receiverActions.merged({ receivers: {
          [data.found.id]: data.found
        } }));
        actions.push(receiverActions.selectedSet({ id: data.found.id }));
    }

    actions.push(errors.actions.updated({ isSuccess: true, id: meta.id }));

    yield put(batchActions(actions));
  }
}
