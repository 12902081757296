import { put, call } from "redux-saga/effects";

import helpers from "helpers";
import config from "../../../../config.json";
import actions from "../../actions";

export default function* logoutWorker() {
  let tokenParams = { key: config.tokenPersistKey };
  let userParams = { key: config.userPersistKey };

  yield call(helpers.persister.remove, tokenParams);
  yield call(helpers.persister.remove, userParams);

  yield put(actions.logout());
}
