import React from "react";
import {
  Typography,
  IconButton,
  Grid,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
  Button,
} from "@mui/material";

import {
  Save as SaveIcon,
  Cancel as CancelIcon,
  VpnKey as VpnKeyIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material";

import usePassword from "./service.js";
import { EditPasswordButton } from "./buttonEditPassword.js";

const ChangePassword = ({ showNotif }) => {
  const {
    errors,
    currentPassword,
    newPassword,
    confirmNewPassword,
    isEditingPassword,
    isCurrentPasswordVisible,
    setIsCurrentPasswordVisible,
    isNewPasswordVisible,
    setIsNewPasswordVisible,
    isConfirmNewPasswordVisible,
    setIsConfirmNewPasswordVisible,
    // passwordConfirmationError,
    handleEditPassword,
    handleSavePassword,
    handleCancelPasswordEdit,
    handleCurrentPasswordChange,
    handleNewPasswordChange,
    handleConfirmNewPasswordChange,
  } = usePassword(showNotif);

  return (
    <>
      <EditPasswordButton onClick={handleEditPassword}  >
        Edit password <VpnKeyIcon fontSize="small" />
      </EditPasswordButton>
      {isEditingPassword && (
        <>
          <Grid item>
            <FormControl variant="outlined" margin="normal">
              <InputLabel htmlFor="current-password">
                Current Password
              </InputLabel>
              <OutlinedInput
                id="current-password"
                type={isCurrentPasswordVisible ? "text" : "password"}
                value={currentPassword}
                onChange={handleCurrentPasswordChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setIsCurrentPasswordVisible((current) => !current)
                      }
                      edge="end"
                    >
                      {isCurrentPasswordVisible ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Current Password"
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl variant="outlined" margin="normal">
              <InputLabel htmlFor="new-password">New Password</InputLabel>
              <OutlinedInput
                id="new-password"
                type={isNewPasswordVisible ? "text" : "password"}
                value={newPassword}
                onChange={handleNewPasswordChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setIsNewPasswordVisible((current) => !current)
                      }
                      edge="end"
                    >
                      {isNewPasswordVisible ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="New Password"
              />
            </FormControl>

            {/* Show password length error message */}
            {/* {errors.newPassword && (
              <Typography
                helperText= {passwordConfirmationError}
                error={isPasswordConfirmationError}
              >
                {errors.newPassword}
              </Typography>
            )} */}

            {errors.newPassword && (
              <Typography variant="subtitle2" color="error">
                {errors.newPassword}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <FormControl variant="outlined" margin="normal">
              <InputLabel htmlFor="confirm-new-password">
                Confirm New Password
              </InputLabel>
              <OutlinedInput
                id="confirm-new-password"
                type={isConfirmNewPasswordVisible ? "text" : "password"}
                value={confirmNewPassword}
                onChange={handleConfirmNewPasswordChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setIsConfirmNewPasswordVisible((current) => !current)
                      }
                      edge="end"
                    >
                      {isConfirmNewPasswordVisible ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm New Password"

                  // // Utilisez la prop helperText pour afficher le message d'erreur
                  // helperText={localErrors.passwordConfirmation}
                  // // Utilisez la prop error pour activer le style d'erreur
                  // error={localErrors.passwordConfirmation !== null}
              />
            </FormControl>
            {/* Show password confirmation error message */}
            {errors.passwordConfirmation && (
              <Typography variant="subtitle2" color="error">
                {errors.passwordConfirmation}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Button
                  onClick={handleSavePassword}
                  startIcon={<SaveIcon fontSize="small" />}
                  sx={{ backgroundColor: "#E5F3FF", color: "#267DC9" ,marginRight: "13px"}}
                >
                  Save
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={handleCancelPasswordEdit}
                  startIcon={<CancelIcon fontSize="small" />}
                  sx={{ backgroundColor: "#E5F3FF", color: "#267DC9" }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default ChangePassword;
