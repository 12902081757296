import { memoize } from "proxy-memoize";
import selectAll from "../all";
import helpers from "helpers";

const manyByUsername = memoize(({ state, username }) => {
  const clients = selectAll(state);

  let result = [];

  if (helpers.validator.isEmptyString(username)) return null;
  if (!helpers.validator.isObject(clients)) return null;
  if (helpers.validator.isEmptyObject(clients)) return null;

  Object.entries(clients).forEach(([_, client]) => {
    if (client?.username?.includes(username))
      result.push({
        profile: client.profile ?? null,
        name: `${client?.firstName} ${client?.lastName}`,
        id: client?.id,
      });
  });

  return result.length === 0 ? null : result;
});

export default manyByUsername;
