import * as React from 'react'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { Badge } from '@mui/material'
import GppBadIcon from '@mui/icons-material/GppBad'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive'
import AirplanemodeInactiveIcon from '@mui/icons-material/AirplanemodeInactive'
import DeleteIcon from '@mui/icons-material/Delete'
import PhoneIcon from '@mui/icons-material/Phone'
import { Grid } from '@mui/material'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import PublicIcon from '@mui/icons-material/Public'
import FingerprintIcon from '@mui/icons-material/Fingerprint'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { useSelector, useDispatch } from 'react-redux'
import selectors from '../../selectors'
import actions from '../../actions'
import { useNavigate } from 'react-router-dom'

const menuId = 'primary-search-account-menu'

export default function ClientCard({ id, handleOpen }) {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const client = useSelector(state => selectors.detailedOneById({ state, id }))

	const [anchorEl, setAnchorEl] = React.useState(null)
	const isMenuOpen = Boolean(anchorEl)

	const handleProfileMenuOpen = React.useCallback(event => {
		setAnchorEl(event.currentTarget)
	}, [])

	const handleMenuClose = React.useCallback(() => {
		setAnchorEl(null)
	}, [])

	const handleActivate = React.useCallback(() => {
		dispatch(actions.changeStatus({ id, status: 'active' }))
		handleMenuClose()
	}, [dispatch, id])

	const handleDeactivate = React.useCallback(() => {
		handleOpen({ type: 'deactivate', id })
		handleMenuClose()
	}, [id])

	const handleDelete = React.useCallback(() => {
		handleOpen({ type: 'delete', id })
		handleMenuClose()
	}, [id])

	const renderMenu = (
		<Menu
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			id={menuId}
			keepMounted
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			open={isMenuOpen}
			onClose={handleMenuClose}
		>
			{!client?.account?.isActive && (
				<MenuItem onClick={handleActivate}>
					<Button
						variant='text'
						startIcon={<AirplanemodeActiveIcon />}
						size='small'
						color='success'
					>
						Activate
					</Button>
				</MenuItem>
			)}
			{client?.account?.isActive && (
				<MenuItem onClick={handleDeactivate}>
					<Button
						variant='text'
						startIcon={<AirplanemodeInactiveIcon />}
						size='small'
						color='primary'
					>
						Deactivate
					</Button>
				</MenuItem>
			)}
			<MenuItem onClick={handleDelete}>
				<Button
					variant='text'
					startIcon={<DeleteIcon />}
					size='small'
					color='error'
				>
					Delete
				</Button>
			</MenuItem>
		</Menu>
	)

	const handleGotoDetails = React.useCallback(() => {
		dispatch(actions.selectedSet({ id }))
		navigate('/dashboard/clients/detailed')
	}, [navigate, dispatch, id])

	return (
		<>
			<Card sx={{ width: '95%' }}>
				<CardHeader
					avatar={
						<Badge
							variant={
								client?.profile?.photo ||
								client?.profile?.isAccepted ||
								client?.profile?.isInreview
									? 'dot'
									: 'standard'
							}
							color={
								client?.profile?.isInreview
									? 'warning'
									: client?.profile?.isAccepted
									? 'success'
									: 'error'
							}
						>
							<Button
								onClick={() =>
									handleOpen({
										type: 'profile',
										id,
										open: !client?.profile?.isAccepted && client?.profile?.photo,
									})
								}
							>
								<Avatar src={client?.profile?.photo} sx={{ width: 50, height: 50 }} />
							</Button>
						</Badge>
					}
					action={
						<IconButton aria-controls={menuId} onClick={handleProfileMenuOpen}>
							{client?.account?.isVerified ? (
								<VerifiedUserIcon
									color={client?.account?.isActive ? 'success' : 'primary'}
								/>
							) : (
								<GppBadIcon color={client?.account?.isActive ? 'primary' : 'error'} />
							)}
						</IconButton>
					}
					title={client?.fullName}
					subheader={
						<Typography variant='caption'>{client?.createdAt}</Typography>
					}
				/>
				<CardContent>
					<Grid container flexDirection='row' alignItems='center'>
						<Badge
							variant={client?.phone?.value ? 'dot' : 'standard'}
							color={client?.phone?.isVerified ? 'success' : 'error'}
						>
							<PhoneIcon color='action' />
						</Badge>
						{client?.phone?.value && (
							<Typography
								variant='body2'
								color='text.secondary'
								style={{ marginLeft: 20 }}
							>
								{client?.phone?.value}
							</Typography>
						)}
					</Grid>
					{client?.email?.value && (
						<Grid
							container
							flexDirection='row'
							alignItems='center'
							style={{ marginTop: 10 }}
						>
							<AlternateEmailIcon color='action' />
							<Typography
								variant='body2'
								color='text.secondary'
								style={{ marginLeft: 20 }}
							>
								{client?.email?.value}
							</Typography>
						</Grid>
					)}
					{client?.nationality && (
						<Grid
							container
							flexDirection='row'
							alignItems='center'
							style={{ marginTop: 10 }}
						>
							<PublicIcon color='action' />
							<Typography
								variant='body2'
								color='text.secondary'
								style={{ marginLeft: 20 }}
							>
								{client?.nationality}
							</Typography>
						</Grid>
					)}
				</CardContent>
				<CardActions disableSpacing>
					<Grid
						container
						flexDirection='row-reverse'
						justifyContent='space-between'
					>
						<Badge
							variant={client?.identity?.values?.length === 2 ? 'dot' : 'standard'}
							color={
								client?.identity?.isInreview
									? 'warning'
									: client?.identity?.isAccepted
									? 'success'
									: 'error'
							}
						>
							<IconButton
								onClick={() =>
									handleOpen({
										type: 'identity',
										id,
										open:
											client?.identity?.values?.length === 2 &&
											!client?.identity?.isAccepted,
									})
								}
							>
								<FingerprintIcon color='action' />
							</IconButton>
						</Badge>
						<IconButton color='inherit' onClick={handleGotoDetails}>
							<MoreHorizIcon />
						</IconButton>
					</Grid>
				</CardActions>
			</Card>
			{renderMenu}
		</>
	)
}
