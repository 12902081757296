export default {
	0: {
		id: '0',
		addedAt: '2000-12-29T13:20:49.146Z',
		creatorId: '2',
		firstName: 'HAMZA',
		lastName: 'LACHQAR',
		phone: '+212 655093497',
		identity: {
			type: 'cin',
			value: 'B147857',
		},
		status: [
			{
				value: 'active',
				date: '2000-12-29T13:20:49.146Z',
			},
		],
	},

	1: {
		id: '1',
		addedAt: '2000-12-29T13:20:49.146Z',
		creatorId: '1',
		firstName: 'Redouane',
		lastName: 'BELQORCHI',
		phone: '+212 655093497',
		identity: {
			type: 'cin',
			value: 'B147857',
		},
		status: [
			{
				value: 'inactive',
				date: '2000-12-29T13:20:49.146Z',
			},
		],
	},
}
