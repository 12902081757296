import React from "react";

import { Typography, Box, Grid, TextField, Button } from "@mui/material";

import {
  Edit as EditIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
  Phone as PhoneIcon,
} from "@mui/icons-material";
import { EditButton} from "./editButton.js";
import usePhoneNumber from "./service.js";

const ChangePhoneNumber = ({ showNotif }) => {
  const {
    oldPhone,
    phoneNumber,
    handleEditPhoneNumber,
    isEditingPhoneNumber,
    handlePhoneNumberChange,
    isVerificationCodeVisible,
    verificationCode,
    handleVerificationCodeChange,
    handleCancelPhoneVerification,
    handleSavePhoneNumber,
    showVerificationCode,
    isCodeConfirmationError,
    codeConfirmationError,
  } = usePhoneNumber(showNotif);

  return (
    <>
      <Typography variant="subtitle1">
        <Box display="flex" alignItems="center">
          <PhoneIcon fontSize="small" sx={{ marginRight: "9px" }} />
          {oldPhone}
          <EditButton onClick={handleEditPhoneNumber} >
            <EditIcon fontSize="small"   />
          </EditButton>
        </Box>
      </Typography>

      {isEditingPhoneNumber && (
        <>
          <Grid item>
            <TextField
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              label="Phone Number"
              variant="outlined"
            
            />
          </Grid>
          {isVerificationCodeVisible && (
            <Grid item>
              <TextField
                value={verificationCode}
                onChange={handleVerificationCodeChange}
                label="Verification Code"
                variant="outlined"
                helperText={codeConfirmationError}
                error={isCodeConfirmationError}
              />
            </Grid>
          )}
          <Grid item>
            {isVerificationCodeVisible ? (
              <>
                <Button
                  onClick={handleCancelPhoneVerification}
                  startIcon={<CancelIcon fontSize="small" />}
                  sx={{ backgroundColor: "#E5F3FF", color: "#267DC9" ,marginRight: "13px"}}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSavePhoneNumber}
                  startIcon={<SaveIcon fontSize="small" />}
                  sx={{ backgroundColor:  "#E5F3FF", color: "#267DC9" }}
                >
                  Save
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={showVerificationCode}
                  startIcon={<SaveIcon fontSize="small" />}
                  sx={{ backgroundColor: "#E5F3FF", color: "#267DC9" ,marginRight: "13px"}}
                >
                  Verify
                </Button>
                <Button
                  onClick={handleCancelPhoneVerification}
                  startIcon={<CancelIcon fontSize="small" />}
                  sx={{ backgroundColor: "#E5F3FF", color: "#267DC9" }}
                >
                  Cancel
                </Button>
              </>
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default ChangePhoneNumber;
