import layouts from './layout'
import { Route, Routes } from 'react-router-dom'
import features from './features'
import components from './components'

export default function App() {
	return (
		<>
			<Routes>
				<Route exact path='/' element={<features.auth.ui.Login />} />
				<Route
					exact
					path='/forgot-password'
					element={<features.auth.ui.ForgotPassword />}
				/>
				<Route exact path='/profile' element={<features.auth.ui.Profile />} />

				<Route path='/dashboard' element={<layouts.Dashboard />}>
					<Route
						exact
						path='/dashboard/paths'
						element={<features.paths.ui.Main />}
					/>
					<Route
						exact
						path='/dashboard/expeditions'
						element={<features.expeditions.ui.Main />}
					/>
					<Route
						exact
						path='/dashboard/clients'
						element={<features.clients.ui.Main />}
					/>
					<Route
						exact
						path='/dashboard/clients/detailed'
						element={<features.clients.ui.Client />}
					/>
					<Route
						exact
						path='/dashboard/payments'
						element={<features.payments.useCases.Main />}
					/>
					<Route
						exact
						path='/dashboard/not-members'
						element={<features.receivers.ui.Main />}
					/>
				</Route>
			</Routes>
			<components.Loading />
			<components.Snackbar />
		</>
	)
}
