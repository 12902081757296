import filteredIds from "./filteredIds";
import detailedOneById from "./detailedOneById";
import detailedSelected from "./detailedSelected";
import filter from "./filter";
import selected from "./selected";
import oneById from "./oneById";

export default Object.freeze({
  filteredIds,
  detailedOneById,
  detailedSelected,
  filter,
  selected,
  oneById,
});
