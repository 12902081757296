import { put, select } from "redux-saga/effects";

import helpers from "helpers";
import config from "../../../../config.json";
import errors from "store/errors";
import receiverActions from "../../actions";
import receiverSelectors from "../../selectors";
import { batchActions } from "redux-batched-actions";

export default function* changeStatusWorker({ payload = {}, meta = {} }) {
  let actions = [];

  const receiverId = yield select(receiverSelectors.selected);

  let { data } = yield helpers.sagas.worker({
    method: "POST",
    url: config.endpoints.receivers.changeStatus,
    data: {...payload, id: payload.id ?? receiverId },
    loadingId: meta.id,
  });

  if (data?.success) {
    if(helpers.getStatus(data?.updated?.status) === "deleted")
      actions.push(receiverActions.removed({ id: data.updated.id }));
    else
      actions.push(receiverActions.statusChanged({ receiver: data.updated }));
  
      actions.push(errors.actions.updated({ isSuccess: true, id: meta.id }));
      yield put(batchActions(actions));
  }
}
