import types from "../actionsTypes";
import { produce } from "immer";
import helpers from "helpers";

import initialState from "../initialState";

const clientsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  return produce(state, (draft) => {
    switch (type) {
      case types.merged:
        if (
          helpers.validator.isObject(payload?.clients) &&
          !helpers.validator.isEmptyObject(payload?.clients)
        )
          Object.entries(payload.clients).forEach(([id, client]) => {
            if (
              !helpers.validator.isEmptyObject(client) &&
              !helpers.validator.isEmptyString(id)
            )
              draft.all[id] =  client
          });
        break;

      case types.updated:
        if (
          helpers.validator.isObject(payload?.client) &&
          !helpers.validator.isEmptyObject(payload?.client) &&
          !helpers.validator.isEmptyString(payload?.client?.id)
        )
          draft.all[payload.client.id] =  payload.client
        break;

      case types.selectedSet:
        draft.selected = payload?.id;
        break;
      
      case types.removed:
        if (!helpers.validator.isEmptyString(payload?.id)) {
          delete draft.all[payload.id];
          if (draft.selected === payload.id) draft.selected = null;
        }
        break;

      case types.filterUpdated:
        draft.filter = { ...draft.filter, ...payload };
        break;

      default:
        break;
    }
  });
};

export default clientsReducer;
