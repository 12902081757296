import { put } from "redux-saga/effects";

import helpers from "helpers";
import config from "../../../../config.json";
import errors from "store/errors";
import clientsActions from "../../actions";

export default function* getOne({ payload, meta = {} }) {
  let { data } = yield helpers.sagas.worker({
    url: config.endpoints.users.root,
    params: payload,
    loadingId: meta.id,
  });

  if (data?.success && data?.found) {
    yield put(clientsActions.updated({ client: data.found }));
    yield put(errors.actions.updated({ isSuccess: true, id: meta.id }));
  }
}
