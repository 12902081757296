import get from "./get";
import changeStatus from "./change-status";
import filterUpdated from "./filter-updated";
import merged from "./merged";
import selectedSet from "./selected-set";
import updated from "./updated";
import remove from "./remove";
import hardDelete from "./hardDelete";
import removed from "./removed";

export default Object.freeze({
  get,
  changeStatus,
  filterUpdated,
  merged,
  selectedSet,
  updated,
  remove,
  hardDelete,
  removed,
});
