import { useSelector, useDispatch } from "react-redux";
import selectors from "../../selectors";
import { useCallback } from "react";
import { Box, Button, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import styles from "./styles";
import actions from "../../actions";

export default function Accept({ handleReject, handleCancel }) {
  const dispatch = useDispatch();

  const client = useSelector(selectors.detailedSelected);

  const changeStatus = useCallback(() => {
    dispatch(
      actions.changeProfileStatus({
        status: "accept",
      })
    );
  }, [dispatch]);

  return (
    <Box sx={styles.container}>
      <Grid container justifyContent="center">
        <img
          src={client?.profile?.photo}
          style={{ width: 350, height: 350, borderRadius: 350 / 2 }}
        />
      </Grid>
      <Grid container justifyContent="space-between" style={{ marginTop: 30 }}>
        <Grid xs={6}>
          <Button
            onClick={handleCancel}
            variant="contained"
            size="small"
            color="primary"
          >
            Cancel
          </Button>
        </Grid>
        <Grid xs={6} container justifyContent="space-evenly">
          <Button
            onClick={handleReject}
            startIcon={<CloseIcon />}
            variant="contained"
            size="small"
            color="error"
          >
            Reject
          </Button>
          <Button
            onClick={changeStatus}
            startIcon={<CheckIcon />}
            variant="contained"
            size="small"
            color="success"
          >
            Accept
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
