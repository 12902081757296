/**
 * @type derived
 * @dependencies clients
 * @description selects receiver info
 * @params state, receiverId as id
 * @returns Object | null
 * @detailed_return  receiver info, creator profile photo
 */

import { memoize } from "proxy-memoize";
import selectAll from "../all";
import clientsSelectors from "features/clients/selectors";
import helpers from "helpers";

export default memoize(({ state, id }) => {
  if (helpers.validator.isEmptyString(id)) return null;

  let receivers = selectAll(state);
  if (!receivers) return null;

  const selectedReceiver = receivers[id];
  if (
    !helpers.validator.isObject(selectedReceiver) ||
    helpers.validator.isEmptyObject(selectedReceiver)
  )
    return null;

  let creator = {};

  if (!helpers.validator.isEmptyString(selectedReceiver.creator))
    creator = clientsSelectors.detailedOneById({
      state,
      id: selectedReceiver.creator,
    });

    console.log("creator id", selectedReceiver.creator)

  return {
    fullName: `${selectedReceiver.firstName} ${selectedReceiver.lastName}`,
    phone: selectedReceiver.phone,
    addedAt: `${helpers.dateTime.getDate(
      selectedReceiver.addedAt
    )} ${helpers.dateTime.getTime(selectedReceiver.addedAt)}`,
    identity: selectedReceiver.identity,
    creator: {
      profile: creator ? creator.profile?.photo : null,
      fullName: creator ? creator.fullName : null,
      id: selectedReceiver.creator,
    },
    isActive: helpers.getStatus(selectedReceiver.status) === "active",
  };
});
