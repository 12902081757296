export default {
	0: {
		id: '0',
		isDraft: false,
		addedAt: Date(Date.now()),
		startAddress: {
			_id: '63458a5812314552b2a7432e',
			city: 'Marrakech',
			lat: 14.1457,
			lng: 14.2569,
			place_id: '147852369',
		},
		endAddress: {
			_id: '63458a5812314552b2a7432f',
			city: 'Casablanca',
			lat: 142.1457,
			lng: 12.2569,
			place_id: '147852369',
		},
		startedAt: Date(Date.now()),
		capacity: 10,
		vehicleType: 'car',
		restPoints: [
			{
				_id: '63458a5812314552b2a74330',
				city: 'Ben guerir',
				lat: 12.1457,
				lng: 15.2569,
				place_id: '147852369',
			},
			{
				_id: { $oid: '63458a5812314552b2a74331' },
				city: 'Skhor rhamna',
				lat: 147852.1457,
				lng: 147852.2569,
				place_id: '147852369',
			},
		],
		creatorId: '0',
		status: [
			{
				value: 'approved',
				date: { $date: { $numberLong: '1665501652003' } },
				_id: '63458a5812314552b2a74332',
			},
		],
	},
	1: {
		id: '1',
		creatorId: '1',
		startedAt: Date(Date.now()),
		startAddress: {
			_id: '63458a5812314552b2a7432e',
			city: 'Tanger',
			lat: 14.1457,
			lng: 14.2569,
			place_id: '147852369',
		},
		endAddress: {
			_id: '63458a5812314552b2a7432f',
			city: 'Casablanca',
			lat: 142.1457,
			lng: 12.2569,
			place_id: '147852369',
		},
		vehicleType: 'train',
		status: [
			{
				value: 'inreview',
				date: { $date: { $numberLong: '1665501652003' } },
				_id: '63458a5812314552b2a74332',
			},
		],
		capacity: 30,
	},
	2: {
		id: '2',
		creatorId: '2',
		startedAt: Date(Date.now()),
		startAddress: {
			_id: '63458a5812314552b2a7432e',
			city: 'Laayoun',
			lat: 14.1457,
			lng: 14.2569,
			place_id: '147852369',
		},
		endAddress: {
			_id: '63458a5812314552b2a7432f',
			city: 'Rabat',
			lat: 142.1457,
			lng: 12.2569,
			place_id: '147852369',
		},
		restPoints: [
			{
				_id: '63458a5812314552b2a74330',
				city: 'Ben guerir',
				lat: 12.1457,
				lng: 15.2569,
				place_id: '147852369',
			},
			{
				_id: { $oid: '63458a5812314552b2a74331' },
				city: 'Skhor rhamna',
				lat: 147852.1457,
				lng: 147852.2569,
				place_id: '147852369',
			},
		],
		vehicleType: 'car',
		status: [
			{
				value: 'rejected',
				date: { $date: { $numberLong: '1665501652003' } },
				_id: '63458a5812314552b2a74332',
			},
		],
		capacity: 5,
	},
	3: {
		id: '3',
		creatorId: '0',
		startedAt: Date(Date.now()),
		startAddress: {
			_id: '63458a5812314552b2a7432e',
			city: 'Settat',
			lat: 14.1457,
			lng: 14.2569,
			place_id: '147852369',
		},
		endAddress: {
			_id: '63458a5812314552b2a7432f',
			city: 'Fes',
			lat: 142.1457,
			lng: 12.2569,
			place_id: '147852369',
		},
		vehicleType: 'bike',
		status: [
			{
				value: 'inreview',
				date: { $date: { $numberLong: '1665501652003' } },
				_id: '63458a5812314552b2a74332',
			},
		],
		capacity: 2,
	},
	4: {
		id: '4',
		creatorId: '1',
		startedAt: Date(Date.now()),
		startAddress: {
			_id: '63458a5812314552b2a7432e',
			city: 'Marrakech',
			lat: 14.1457,
			lng: 14.2569,
			place_id: '147852369',
		},
		endAddress: {
			_id: '63458a5812314552b2a7432f',
			city: 'Ifran',
			lat: 142.1457,
			lng: 12.2569,
			place_id: '147852369',
		},
		vehicleType: 'taxi',
		status: [
			{
				value: 'approved',
				date: { $date: { $numberLong: '1665501652003' } },
				_id: '63458a5812314552b2a74332',
			},
		],
		capacity: 10,
	},
	5: {
		id: '5',
		creatorId: '2',
		startedAt: Date(Date.now()),
		startAddress: {
			_id: '63458a5812314552b2a7432e',
			city: 'Safe',
			lat: 14.1457,
			lng: 14.2569,
			place_id: '147852369',
		},
		endAddress: {
			_id: '63458a5812314552b2a7432f',
			city: 'Marrakech',
			lat: 142.1457,
			lng: 12.2569,
			place_id: '147852369',
		},
		vehicleType: 'truck',
		status: [
			{
				value: 'rejected',
				date: { $date: { $numberLong: '1665501652003' } },
				_id: '63458a5812314552b2a74332',
			},
		],
		capacity: 200,
	},
}
