import { put, select } from "redux-saga/effects";

import helpers from "helpers";
import config from "../../../../config.json";
import errors from "store/errors";
import clientsActions from "features/clients/actions";
import expeditionsActions from "../../actions";
import normalizer from "../../normalizer";
import expeditionsSelectors from "../../selectors"
import { batchActions } from "redux-batched-actions";
import receiversActions from "features/receivers/actions"


export default function* changeStatus({ payload = {}, meta = {} }) {
  let actions = [];

  const expeditionId = yield select(expeditionsSelectors.selected);

  let { data } = yield helpers.sagas.worker({
    method: "POST",
    url: config.endpoints.expeditions.changeStatus,
    data: {...payload, id: payload.id ?? expeditionId },
    loadingId: meta.id,
  });

  if (data?.success && data?.updated) {
    if(helpers.getStatus(data?.updated?.status) === "deleted")
      actions.push(expeditionsActions.removed({ id: data.updated.id }));
    else {
      const normalized = normalizer([data.updated]);

      console.log("normalized expeditions", normalized)

      actions.push(clientsActions.merged({ clients: normalized.users }));
      actions.push(receiversActions.merged({ receivers: normalized.receivers }));
      actions.push(expeditionsActions.merged({ expeditions: normalized.expeditions }));
    }

    actions.push(errors.actions.updated({ isSuccess: true, id: meta.id }));

    yield put(batchActions(actions));
  }
}