import types from '../actionsTypes'
import { produce } from 'immer'
import helpers from 'helpers'
import initialState from '../initialState'

const pathsReducer = (state = initialState, action) => {
	const { type, payload } = action

	return produce(state, draft => {
		switch (type) {
			case types.merged:
				if (
					helpers.validator.isObject(payload?.paths) &&
					!helpers.validator.isEmptyObject(payload?.paths)
				)
					Object.entries(payload.paths).forEach(([id, path]) => {
						if (
							!helpers.validator.isEmptyObject(path) &&
							!helpers.validator.isEmptyString(id)
						)
							draft.all[id] = helpers.parser.user({
								user: path,
							})
					})
				break

			case types.updated:
				if (
					helpers.validator.isObject(payload?.path) &&
					!helpers.validator.isEmptyObject(payload?.path) &&
					!helpers.validator.isEmptyString(payload?.path?.id)
				)
					draft.all[payload.path.id] = helpers.parser.user({
						user: payload.path,
					})
				break

			case types.selectedSet:
				draft.selected = payload?.id
				break

			case types.filterUpdated:
				draft.filter = { ...draft.filter, ...payload }
				break
			
			      
			case types.removed:
				if (!helpers.validator.isEmptyString(payload?.id)) {
					delete draft.all[payload.id];
					if (draft.selected === payload.id) draft.selected = null;
				}
			break;

			default:
				break
		}
	})
}

export default pathsReducer
