import Carousel from 'react-material-ui-carousel'
import { useSelector } from 'react-redux'
import selectors from '../../selectors'

export default function IdentityCarousel() {
	const client = useSelector(selectors.detailedSelected)

	if (!client?.identity?.values?.length) return null
	return (
		<Carousel navButtonsAlwaysVisible height={200} sx={{ width: 400 }}>
			{client?.identity?.values?.map(prop => (
				<img src={prop} />
			))}
		</Carousel>
	)
}
