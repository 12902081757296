import React from "react";
import { Avatar, Typography, IconButton, Badge } from "@mui/material";

import useHeader, { EditPictureIcon } from "./service.js";

const Header = () => {
  const { profilePicture, handleEditProfilePicture, user} = useHeader();

  return (
    <>
      <Badge
        badgeContent={
          <IconButton
            title="Edit profile picture"
            onClick={handleEditProfilePicture}
          >
            <EditPictureIcon />
          </IconButton>
        }
      >
        <Avatar
          alt="Profile"
          src={profilePicture}
          sx={{ width: 150, height: 150, marginBottom: 2 }}
        />
      </Badge>

      <Typography variant="h5">{user.email}</Typography>
      <Typography variant="subtitle1" gutterBottom>
        {user.name}
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        CIN: {user.CIN}
      </Typography>
    </>
  );
};

export default Header;
