import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import GroupsIcon from "@mui/icons-material/Groups";
import RouteIcon from "@mui/icons-material/Route";
import RedeemIcon from "@mui/icons-material/Redeem";
import { NavLink } from "react-router-dom";
import PaymentsIcon from "@mui/icons-material/Payments";
import PersonOffIcon from "@mui/icons-material/PersonOff";
//import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";

const menuId = "primary-search-account-menu";

export default function DenseAppBar() {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleGoToProfile = () => navigate('/profile')

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleGoToProfile}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>Logout</MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar position="static" color="transparent">
        <Toolbar variant="dense">
          <Grid container alignItems="center">
            <Grid xs={11} item container alignItems="center">
              <Grid xs={10} item container alignItems="center">
                <Grid xs={2}>
                  <NavLink
                    to="/dashboard/paths"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    {({ isActive }) => (
                      <Button
                        variant="text"
                        startIcon={<RouteIcon />}
                        size="small"
                        color={isActive ? "primary" : "inherit"}
                      >
                        Paths
                      </Button>
                    )}
                  </NavLink>
                </Grid>
                <Grid xs={2} item>
                  <NavLink
                    to="/dashboard/expeditions"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    {({ isActive }) => (
                      <Button
                        variant="text"
                        startIcon={<RedeemIcon />}
                        size="small"
                        color={isActive ? "primary" : "inherit"}
                      >
                        Expeditions
                      </Button>
                    )}
                  </NavLink>
                </Grid>
                <Grid xs={2} item>
                  <NavLink
                    to="/dashboard/clients"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    {({ isActive }) => (
                      <Button
                        variant="text"
                        startIcon={<GroupsIcon />}
                        size="small"
                        color={isActive ? "primary" : "inherit"}
                      >
                        Members
                      </Button>
                    )}
                  </NavLink>
                </Grid>
                <Grid xs={2} item>
                  <NavLink
                    to="/dashboard/payments"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    {({ isActive }) => (
                      <Button
                        variant="text"
                        startIcon={<PaymentsIcon />}
                        size="small"
                        color={isActive ? "primary" : "inherit"}
                      >
                        Payments
                      </Button>
                    )}
                  </NavLink>
                </Grid>
                <Grid xs={2} item>
                  <NavLink
                    to="/dashboard/not-members"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    {({ isActive }) => (
                      <Button
                        variant="text"
                        startIcon={<PersonOffIcon />}
                        size="small"
                        color={isActive ? "primary" : "inherit"}
                      >
                        Not members
                      </Button>
                    )}
                  </NavLink>
                </Grid>
              </Grid>
              <Grid xs={2} item container alignItems="center">
                {/* <Button
                  startIcon={<SearchIcon />}
                  variant="text"
                  size="small"
                  color="inherit"
                >
                  Search ...
                </Button> */}
              </Grid>
            </Grid>
            <Grid xs={1} item container justifyContent="flex-end">
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </>
  );
}
