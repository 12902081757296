import { put, select } from "redux-saga/effects";

import helpers from "helpers";
import config from "../../../../config.json";
import errors from "store/errors";
import clientsActions from "../../actions";
import clientsSelectors from "../../selectors";
import { batchActions } from "redux-batched-actions";

export default function* changeStatus({ payload = {}, meta = {} }) {
  let actions = [];

  const userId = yield select(clientsSelectors.selected);

  let { data } = yield helpers.sagas.worker({
    method: "POST",
    url: config.endpoints.users.changeStatus,
    data: {...payload, userId: userId ?? payload.userId},
    loadingId: meta.id,
  });

  if (data?.success) {
    if(helpers.getStatus(data?.updated?.status) === "deleted")
      actions.push(clientsActions.removed({ id: data.updated.id }));
    else
      actions.push(clientsActions.updated({ client: data.updated }));
  
      actions.push(errors.actions.updated({ isSuccess: true, id: meta.id }));
      yield put(batchActions(actions));
  }
}