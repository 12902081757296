import { useState } from "react";

import user from "../constant/user";

const usePhoneNumber = (showNotif) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isEditingPhoneNumber, setIsEditingPhoneNumber] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [isVerificationCodeVisible, setIsVerificationCodeVisible] =
    useState(false);
  const [codeConfirmationError, setCodeConfirmationError] = useState("");

  const handlePhoneNumberChange = (event) => setPhoneNumber(event.target.value);

  const handleEditPhoneNumber = () =>
    setIsEditingPhoneNumber((current) => !current);

  const handleSavePhoneNumber = () => {
    if (verificationCode === "") {
      setCodeConfirmationError("Please enter the verification code.");
    } else if (verificationCode !== "123456") {
      setCodeConfirmationError(
        "Incorrect verification code. Please try again."
      );
    } else {
      setIsEditingPhoneNumber(false); // Exit the edit mode for phone number
      setVerificationCode(""); // Clear the verification code input
      setPhoneNumber(phoneNumber); // Save the new phone number
      setCodeConfirmationError("");

      showNotif("New Phone number saved !");
    }
  };

  const showVerificationCode = () => setIsVerificationCodeVisible(true);

  const handleCancelPhoneVerification = () => {
    setPhoneNumber(""); // Restore the initial phone number
    setIsEditingPhoneNumber(false); // Exit the edit mode for phone number
    setVerificationCode("");
    setCodeConfirmationError("");
  };

  const handleVerificationCodeChange = (event) =>
    setVerificationCode(event.target.value);

  return {
    oldPhone: user.phoneNumber,
    phoneNumber,
    handleEditPhoneNumber,
    isEditingPhoneNumber,
    handlePhoneNumberChange,
    isVerificationCodeVisible,
    verificationCode,
    handleVerificationCodeChange,
    handleCancelPhoneVerification,
    handleSavePhoneNumber,
    showVerificationCode,
    isCodeConfirmationError: codeConfirmationError ? true : false,
    codeConfirmationError,
  };
};

export default usePhoneNumber;

