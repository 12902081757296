import { useDispatch } from "react-redux";
import actions from "../../actions";
import { useCallback, useEffect, useState } from "react";
import { Grid, TextField, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import helper from "helpers";
import Photos from "./photos";
import styles from "./styles";

export default function Accept({ changeActiveAction, handleClose }) {
  const dispatch = useDispatch();

  const [experationDate, setExperationDate] = useState(null);
  const [identityNumber, setIdentityNumber] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [canAccept, setCanAccept] = useState(false);

  const handleIdentityNumberChange = useCallback((event) =>
    setIdentityNumber(event?.target?.value)
  );

  const handleRejectClick = useCallback(() => changeActiveAction("reject"), []);

  // validate identity number
  useEffect(() => {
    if (identityNumber && !helper.validator.isCin(identityNumber))
      setErrorMessage("Provided value is invalid");
    else setErrorMessage(null);
  }, [identityNumber]);

  // track submission possibility
  useEffect(() => {
    if (experationDate && identityNumber && !errorMessage) setCanAccept(true);
    else setCanAccept(false);
  }, [experationDate, identityNumber, errorMessage]);

  const changeStatus = useCallback(() => {
    if (canAccept)
      dispatch(
        actions.changeIdentityStatus({
          status: "accept",
          identityNumber,
          experationDate,
        })
      );
  }, [dispatch, identityNumber, experationDate, canAccept]);

  return (
    <Grid container justifyContent="space-between" sx={styles.container}>
      <Grid item xs={6}>
        <Photos />
      </Grid>
      <Grid
        item
        xs={6}
        container
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <TextField
          name="identityNumber"
          id="identity-number"
          label="Identity"
          variant="standard"
          size="small"
          style={{ width: "70%" }}
          value={identityNumber}
          helperText={errorMessage}
          FormHelperTextProps={{ style: { color: "red" } }}
          onChange={handleIdentityNumberChange}
        />

        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            openTo="year"
            views={["year", "month", "day"]}
            label="Expires at"
            name="experationDate"
            id="experation-date"
            style={{ width: "70%", marginTop: 20 }}
            value={experationDate}
            onChange={(newValue) => {
              setExperationDate(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                size="small"
                style={{ width: "70%", marginTop: 20 }}
              />
            )}
          />
        </LocalizationProvider>

        <Grid
          Item
          container
          justifyContent="space-between"
          style={{ marginTop: 60 }}
        >
          <Grid xs={6}>
            <Button
              onClick={handleClose}
              variant="contained"
              size="small"
              color="primary"
            >
              Cancel
            </Button>
          </Grid>
          <Grid xs={6} container justifyContent="space-evenly">
            <Button
              onClick={handleRejectClick}
              startIcon={<CloseIcon />}
              variant="contained"
              size="small"
              color="error"
            >
              Reject
            </Button>
            <Button
              onClick={changeStatus}
              startIcon={<CheckIcon />}
              variant="contained"
              size="small"
              color="success"
              disabled={!canAccept}
            >
              Accept
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
