import Components from "components";
import { useState } from "react";
import Reject from "./reject";
import Accept from "./accept";

export default function Identity({ open, handleClose }) {
  const [activeAction, setActiveAction] = useState("accept");

  return (
    <Components.Modal
      open={open}
      handleClose={handleClose}
      renderContent={
        activeAction === "accept" ? (
          <Accept
            handleClose={handleClose}
            changeActiveAction={setActiveAction}
          />
        ) : (
          <Reject
            handleClose={handleClose}
            changeActiveAction={setActiveAction}
          />
        )
      }
    />
  );
}
