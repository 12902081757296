import filteredIds from "./filtered-ids";
import detailedOneById from "./detailedOneById";
import detailedSelected from "./detailedSelected";
import manyByUsername from "./manyByUsername";
import filter from "./filter";
import selected from "./selected";
import oneById from "./oneById";

export default Object.freeze({
  filteredIds,
  detailedOneById,
  detailedSelected,
  manyByUsername,
  filter,
  selected,
  oneById,
});
