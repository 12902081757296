import { put, select } from "redux-saga/effects";

import errors from "store/errors";
import clientsActions from "../../actions";
import pathsSelectors from "../../selectors";
import normalizer from "../../normalizer";
import helpers from "helpers";
import config from "../../../../config.json";
import pathsActions from "../../actions";

export default function* getMany({ meta = {} }) {
  const filter = yield select(pathsSelectors.filter);

  let { data } = yield helpers.sagas.worker({
    url: config.endpoints.paths.root,
    params: { ...filter },
    loadingId: meta.id,
  });

  if (data?.success && data?.found) {
    const normalized = normalizer(data?.found);

    yield put(clientsActions.merged({ clients: normalized.users }));
    yield put(pathsActions.merged({ paths: normalized.paths }));

    yield put(errors.actions.updated({ isSuccess: true, id: meta.id }));
  }
}
