import { all } from "redux-saga/effects";

import features from "../features";

export default function* rootSaga() {
  yield all([
    features.auth.sagas(),
    features.clients.sagas(),
    features.paths.sagas(),
    features.expeditions.sagas(),
    features.payments.sagas(),
    features.receivers.sagas(),
  ]);
}
