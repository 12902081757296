import { put, select } from "redux-saga/effects";

import receiverActions from "../../actions";
import receiverSelectors from "../../selectors";

export default function* getWorker() {
  const filter = yield select(receiverSelectors.filter);

  if (filter?.phone || filter?.receiverId)  yield put(receiverActions.getOne());
  else yield put(receiverActions.getMany());
}
