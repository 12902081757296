import { useState, useEffect } from 'react'
import {
	Grid,
	FormControl,
	InputLabel,
	Input,
	InputAdornment,
	IconButton,
	TextField,
	Button,
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import helpers from 'helpers'
import actions from '../../actions'
import types from '../../actions-types'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import errors from 'store/errors'

const IMAGE_SIZE = 100

export default function Login() {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const [showPassword, setShowPassword] = useState(false)
	const handleClickShowPassword = () => setShowPassword(show => !show)
	const handleMouseDownPassword = event => {
		event.preventDefault()
	}

	// cleanup errors && setup
	useEffect(() => {
		dispatch(errors.actions.cleaned())
		dispatch(actions.setup())
	}, [])

	const [data, setData] = useState({
		email: null,
		password: null,
	})

	const handleChange = event => {
		const target = event.target
		const value = target.value
		const name = target.name

		setData(current => ({
			...current,
			[name]: value,
		}))
	}

	// handle login click
	const handleLogin = () => {
		let payload = {}

		if (!helpers.validator.isUsername(data.email)) payload['email'] = data.email
		else payload['username'] = data.email

		dispatch(
			actions.login({
				...payload,
				password: data.password,
				meta: { id: types.login },
			})
		)
	}

	// handle success
	const { isSuccess, id } = useSelector(errors.selectors.error)

	useEffect(() => {
		if (isSuccess && id === types.login) navigate('/dashboard/paths')
	}, [isSuccess, id, navigate])

	return (
		<Grid
			container
			justifyContent='center'
			alignItems='center'
			style={{ height: 500 }}
		>
			<Grid container justifyContent='center'>
				<img
					src='/icon.png'
					style={{ width: IMAGE_SIZE, height: IMAGE_SIZE }}
					alt='logo'
				/>
			</Grid>
			<Grid container justifyContent='center' alignItems='center'>
				<Grid container justifyContent='center' alignItems='center'>
					<TextField
						name='email'
						id='email-or-username-input'
						label='Email or username'
						variant='standard'
						size='small'
						onChange={handleChange}
						style={{ width: 300 }}
					/>
				</Grid>
				<Grid
					container
					justifyContent='center'
					alignItems='center'
					style={{ marginTop: 20 }}
				>
					<FormControl sx={{ m: 1, width: 300 }} variant='standard'>
						<InputLabel size='small' htmlFor='password'>
							Password
						</InputLabel>
						<Input
							name='password'
							id='password'
							size='small'
							onChange={handleChange}
							type={showPassword ? 'text' : 'password'}
							endAdornment={
								<InputAdornment position='end'>
									<IconButton
										aria-label='toggle password visibility'
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
									>
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							}
						/>
					</FormControl>
				</Grid>
				<Grid
					container
					justifyContent='center'
					alignItems='center'
					style={{ marginTop: 60 }}
				>
					<Button
						variant='contained'
						size='small'
						style={{ width: 200 }}
						onClick={handleLogin}
						color='info'
					>
						Login
					</Button>
				</Grid>
			</Grid>
		</Grid>
	)
}
