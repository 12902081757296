import { put, select } from 'redux-saga/effects'

import errors from 'store/errors'
import actions from '../../actions'
import clientsActions from 'features/clients/actions'
import selectors from '../../selectors'
import normalizer from '../../normalizer'
import helpers from 'helpers'
import config from '../../../../config.json'

export default function* getMany({ meta = {} }) {
	const params = yield select(selectors.filter)

	let { data } = yield helpers.sagas.worker({
		url: config.endpoints.payments.root,
		params,
		loadingId: meta.id,
	})

	if (data?.success && data?.found) {
		const { clients, payments } = normalizer(data?.found)

		yield put(clientsActions.merged({ clients }))
		yield put(actions.merged({ many: payments }))
		yield put(errors.actions.updated({ isSuccess: true, id: meta.id }))
	}
}
