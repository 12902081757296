import get from './getMany'
import changeStatus from './changeStatus'
import filterUpdated from './filterUpdated'
import merged from './merged'
import selectedSet from './selectedSet'
import updated from './updated'
import remove from './remove'
import hardDelete from './hardDelete'
import removed from './removed'
import update from './update'

export default Object.freeze({
	get,
	changeStatus,
	filterUpdated,
	merged,
	selectedSet,
	updated,
	remove,
	hardDelete,
	removed,
	update,
})
