import { memoize } from "proxy-memoize";
import selectAll from "../all";
import helpers from "helpers";
import clientsSelector from "features/clients/selectors";

const detailedOneById = memoize(({ state, id }) => {
  const paths = selectAll(state);

  const path = paths[id]

  if (
    !helpers.validator.isObject(path) ||
    helpers.validator.isEmptyObject(path)
  )
    return null;

  let client = clientsSelector.detailedOneById({
    state,
    id: path?.creatorId,
  });

  //if (!client) return null;
  if(!client) client = {};

  const status = helpers.getStatus(path.status);

  let result = {
    creator: {
      profile: client.profile?.photo,
      isVerified: client.isVerified,
      id: path?.creatorId
    },
    startedAt: `${helpers.dateTime.getDate(
      path.startedAt
    )},  ${helpers.dateTime.getTime(path.startedAt)}`,
    from: path.startAddress?.city,
    to: path.endAddress?.city,
    vehicle: path.vehicleType,
    capacity: path.capacity,
    status,
    isApproved: status === "approved",
    isDeleted: status === "deleted",
    isRejected: status === "rejected",
  };

  return result;
});

export default detailedOneById;
