import * as React from 'react'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import GppBadIcon from '@mui/icons-material/GppBad'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive'
import AirplanemodeInactiveIcon from '@mui/icons-material/AirplanemodeInactive'
import DeleteIcon from '@mui/icons-material/Delete'
import PhoneIcon from '@mui/icons-material/Phone'
import { CardActions, Grid } from '@mui/material'
import FingerprintIcon from '@mui/icons-material/Fingerprint'
import { useSelector, useDispatch } from 'react-redux'
import selectors from '../../selectors'
import actions from '../../actions'
import WorkHistoryIcon from '@mui/icons-material/WorkHistory'
import { useNavigate } from 'react-router-dom'
import clientsActions from 'features/clients/actions'

const menuId = 'primary-search-account-menu'

export default function ReceiversCard({ id, handleOpen }) {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const receiver = useSelector(state =>
		selectors.detailedOneById({ state, id })
	)

	const [anchorEl, setAnchorEl] = React.useState(null)
	const isMenuOpen = Boolean(anchorEl)

	const handleProfileMenuOpen = React.useCallback(event => {
		setAnchorEl(event.currentTarget)
	}, [])

	const handleMenuClose = React.useCallback(() => {
		setAnchorEl(null)
	}, [])

	const handleActivate = React.useCallback(() => {
		dispatch(actions.changeStatus({ id, status: 'active' }))
		handleMenuClose()
	}, [dispatch, id])

	const handleDeactivate = React.useCallback(() => {
		handleOpen({ type: 'deactivate', id })
		handleMenuClose()
	}, [id])

	const handleDelete = React.useCallback(() => {
		handleOpen({ type: 'delete', id })
		handleMenuClose()
	}, [id])

	const handleSeeCreator = React.useCallback(() => {
		dispatch(clientsActions.selectedSet({ id: receiver?.creator?.id }))
		navigate('/dashboard/clients/detailed')
	}, [navigate, dispatch, receiver])

	const renderMenu = (
		<Menu
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			id={menuId}
			keepMounted
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			open={isMenuOpen}
			onClose={handleMenuClose}
		>
			{!receiver?.isActive && (
				<MenuItem onClick={handleActivate}>
					<Button
						variant='text'
						startIcon={<AirplanemodeActiveIcon />}
						size='small'
						color='success'
					>
						Activate
					</Button>
				</MenuItem>
			)}
			{receiver?.isActive && (
				<MenuItem onClick={handleDeactivate}>
					<Button
						variant='text'
						startIcon={<AirplanemodeInactiveIcon />}
						size='small'
						color='primary'
					>
						Deactivate
					</Button>
				</MenuItem>
			)}
			<MenuItem onClick={handleDelete}>
				<Button
					variant='text'
					startIcon={<DeleteIcon />}
					size='small'
					color='error'
				>
					Delete
				</Button>
			</MenuItem>
		</Menu>
	)

	return (
		<>
			<Card sx={{ width: '95%' }}>
				<CardHeader
					action={
						<IconButton aria-controls={menuId} onClick={handleProfileMenuOpen}>
							{receiver?.isActive ? (
								<VerifiedUserIcon color='success' />
							) : (
								<GppBadIcon color='primary' />
							)}
						</IconButton>
					}
					title={
						<Typography variant='caption' color='GrayText'>
							{receiver?.fullName}
						</Typography>
					}
					subheader={
						<Grid container flexDirection='row' alignItems='center'>
							<WorkHistoryIcon fontSize='small' />
							<Typography variant='caption' style={{ marginLeft: 15 }}>
								{receiver?.addedAt}
							</Typography>
						</Grid>
					}
				/>

				<CardContent>
					<Grid container flexDirection='row' alignItems='center'>
						<PhoneIcon color='action' />
						{receiver?.phone && (
							<Typography
								variant='body2'
								color='text.secondary'
								style={{ marginLeft: 20 }}
							>
								{receiver?.phone}
							</Typography>
						)}
					</Grid>
					<Grid
						container
						flexDirection='row'
						alignItems='center'
						style={{ marginTop: 5, marginBottom: 5 }}
					>
						<FingerprintIcon color='action' />
						{receiver?.identity && (
							<>
								<Typography
									variant='body2'
									color='text.secondary'
									style={{ marginLeft: 20 }}
								>
									{receiver?.identity?.type}
								</Typography>
								<Typography
									variant='body2'
									color='text.secondary'
									style={{ marginLeft: 20 }}
								>
									{receiver?.identity?.value}
								</Typography>
							</>
						)}
					</Grid>
				</CardContent>

				<CardActions>
					{receiver?.creator?.fullName && (
						<Grid
							container
							justifyContent={'space-between'}
							alignItems={'center'}
							flexDirection={'row'}
						>
							<Typography variant='caption' color='GrayText' fontWeight={700}>
								Created By
							</Typography>
							<Button onClick={handleSeeCreator}>
								<Typography variant='caption' color='GrayText'>
									{receiver?.creator?.fullName}
								</Typography>
							</Button>
							{receiver?.creator?.profile && (
								<Button onClick={handleSeeCreator}>
									<Avatar
										src={receiver?.creator?.profile}
										alt={`profile-receiver-${receiver?.creator?.profile}`}
										sx={{ width: 50, height: 50 }}
									/>
								</Button>
							)}
						</Grid>
					)}
				</CardActions>
			</Card>
			{renderMenu}
		</>
	)
}
