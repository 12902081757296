import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Badge } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { Grid } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import DepartureBoardIcon from "@mui/icons-material/DepartureBoard";
import LocalAirportIcon from "@mui/icons-material/LocalAirport";
import DirectionsSubwayFilledIcon from "@mui/icons-material/DirectionsSubwayFilled";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import MopedIcon from "@mui/icons-material/Moped";
import LocalTaxiIcon from "@mui/icons-material/LocalTaxi";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import { useDispatch, useSelector } from "react-redux";
import selectors from "../../selectors";
import actions from "../../actions";
import clientActions from 'features/clients/actions'
import { useNavigate } from 'react-router-dom'

const menuId = "primary-search-account-menu";

export default function PathCard({ id, handleOpen }) {
  const dispatch = useDispatch();
	const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const path = useSelector((state) => selectors.detailedOneById({ state, id }));

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleApprove = React.useCallback(() => {
    dispatch(actions.changeStatus({ id, status: "approved" }));
    handleMenuClose();
  }, [dispatch, id]);

  const handleReject = React.useCallback(() => {
    handleOpen({ type: "reject", id });
    handleMenuClose();
  }, [id]);

  const handleDelete = React.useCallback(() => {
    handleOpen({ type: "delete", id });
    handleMenuClose();
  }, [id]);

  const handleGotoCreator = React.useCallback(() => {
		dispatch(clientActions.selectedSet({ id: path?.creator?.id}))
		navigate('/dashboard/clients/detailed')
	}, [navigate, dispatch, path])


  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {!path?.isApproved && (
        <MenuItem onClick={handleApprove}>
          <Button
            variant="text"
            startIcon={<CheckCircleIcon />}
            size="small"
            color="success"
          >
            Approve
          </Button>
        </MenuItem>
      )}
      {!path?.isRejected && (
        <MenuItem onClick={handleReject}>
          <Button
            variant="text"
            startIcon={<CancelIcon />}
            size="small"
            color="error"
          >
            Reject
          </Button>
        </MenuItem>
      )}
      {!path?.isDeleted && (
        <MenuItem onClick={handleDelete}>
          <Button
            variant="text"
            startIcon={<DeleteIcon />}
            size="small"
            color="error"
          >
            Delete
          </Button>
        </MenuItem>
      )}
    </Menu>
  );

  return (
    <>
      <Card sx={{ width: "95%" }}>
        <CardHeader
          avatar={
            <Badge
              variant={
                path?.creator?.profile ||
                path?.creator?.isProfileValid ||
                path?.creator?.isProfileInreview
                  ? "dot"
                  : "standard"
              }
              color={
                path?.creator?.isProfileInreview
                  ? "warning"
                  : path?.creator?.isProfileValid
                  ? "success"
                  : "error"
              }
            >
              <Button onClick={handleGotoCreator}>
                {path?.creator?.profile ? (
                  <Avatar>
                    <img
                      src={path?.creator?.profile}
                      alt={`profile-client-${path?.creator?.profile}`}
                      style={{ width: 50, height: 50 }}
                    />
                  </Avatar>
                ) : (
                  <Avatar />
                )}
              </Button>
            </Badge>
          }
          action={
            <IconButton aria-controls={menuId} onClick={handleProfileMenuOpen}>
              {path?.isApproved ? (
                <CheckCircleIcon color={"success"} />
              ) : path?.isRejected ? (
                <CancelIcon color={"error"} />
              ) : (
                <HourglassTopIcon color="warning" />
              )}
            </IconButton>
          }
          title={
            <Grid
              container
              flexDirection="row"
              alignItems="center"
              justifyContent="space-around"
            >
              <Typography variant="caption">{path?.from}</Typography>
              <ArrowRightAltIcon />
              <Typography variant="caption">{path?.to}</Typography>
            </Grid>
          }
          subheader={
            <Grid container flexDirection="row" alignItems="center">
              <DepartureBoardIcon fontSize="small" />
              <Typography variant="caption" style={{ marginLeft: 15 }}>
                {path?.startedAt}
              </Typography>
            </Grid>
          }
        />

        <CardContent>
          {path?.vehicle && (
            <Grid container flexDirection="row" alignItems="center">
              {path?.vehicle === "taxi" ? (
                <LocalTaxiIcon color="action" fontSize="small" />
              ) : path?.vehicle === "plane" ? (
                <LocalAirportIcon color="action" fontSize="small" />
              ) : path?.vehicle === "bike" ? (
                <DirectionsBikeIcon color="action" fontSize="small" />
              ) : path?.vehicle === "moto" ? (
                <MopedIcon color="action" fontSize="small" />
              ) : path?.vehicle === "train" ? (
                <DirectionsSubwayFilledIcon color="action" fontSize="small" />
              ) : (
                <LocalShippingIcon color="action" fontSize="small" />
              )}
              <Typography
                variant="body2"
                color="text.secondary"
                style={{ marginLeft: 20 }}
              >
                {path.vehicle}
              </Typography>
            </Grid>
          )}
          {path?.capacity && (
            <Grid
              container
              flexDirection="row"
              alignItems="center"
              style={{ marginTop: 10 }}
            >
              <FitnessCenterIcon color="action" fontSize="small" />
              <Typography
                variant="body2"
                color="text.secondary"
                style={{ marginLeft: 20 }}
              >
                {`${path?.capacity}  KG`}
              </Typography>
            </Grid>
          )}
        </CardContent>

        <CardActions disableSpacing>
          <IconButton color="inherit">
            <MoreHorizIcon />
          </IconButton>
        </CardActions>
      </Card>
      {renderMenu}
    </>
  );
}
