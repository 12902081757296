import types from "../../actions-types";

export default function filterUpdated({
  identityType,
  identityValue,
  creatorId,
  userId,
  meta = {},
}) {
  let filter = {};

  if (creatorId) filter["creatorId"] = creatorId;
  if (userId) filter["userId"] = userId;
  if (identityType) filter["identityType"] = identityType;
  if (identityValue) filter["identityValue"] = identityValue;

  return {
    type: types.filterUpdated,
    payload: filter,
    meta,
  };
}
