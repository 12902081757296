import paths from "./paths";
import expeditions from "./expeditions";
import clients from "./clients";
import payments from "./payments";
import auth from "./auth";
import receivers from "./receivers";

export default Object.freeze({
  paths,
  expeditions,
  clients,
  payments,
  auth,
  receivers,
});
