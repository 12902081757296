/**
 * @type simple
 * @description selects all receivers
 * @params state
 * @return Object
 * @detailed_return receivers
 */

import { memoize } from 'proxy-memoize'

export default memoize(state => state?.receivers?.all ?? {})
