import { put, select } from "redux-saga/effects";

import helpers from "helpers";
import config from "../../../../config.json";
import errors from "store/errors";
import paymentsActions from "../../actions";
import clientsActions from "features/clients/actions";
import paymentsSelectors from "../../selectors";
import { batchActions } from "redux-batched-actions";
import normalize from "../../normalizer"

export default function* changeStatus({ payload = {}, meta = {} }) {
  let actions = [];

  const paymentId = yield select(paymentsSelectors.selected);

  let { data } = yield helpers.sagas.worker({
    method: "POST",
    url: config.endpoints.payments.changeStatus,
    data: {...payload, id: payload.id ?? paymentId },
    loadingId: meta.id,
  });

  if (data?.success) {
    if(helpers.getStatus(data?.updated?.status) === "deleted")
      actions.push(paymentsActions.removed({ id: data.updated.id }));
    else {
      const {payments, clients} = normalize([data?.updated])
      actions.push(paymentsActions.merged({ many: payments }));
      actions.push(clientsActions.merged({clients}))
    }

    actions.push(errors.actions.updated({ isSuccess: true, id: meta.id }));
    yield put(batchActions(actions));
  }
}