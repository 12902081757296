/**
 * @filter_format
 * {
 *  status:
 *      inreview
 *      rejected
 *      approved
 *      expired
 *  creatorId, from, to
 * }
 */

import types from "../../actionsTypes";

export default function filterUpdated({ status, creatorId, from, to } = {}) {
  let payload = {};

  if (status) payload["status"] = status;
  if (creatorId) payload["creatorId"] = creatorId;
  if (from) payload["from"] = from;
  if (to) payload["to"] = to;

  return {
    type: types.filterUpdated,
    payload,
  };
}
