import { useDispatch } from "react-redux";
import actions from "../../actions";
import { useCallback, useEffect, useState } from "react";
import { Grid, TextField, Button, IconButton } from "@mui/material";
import helper from "helpers";
import Photos from "./photos";
import styles from "./styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function Reject({ handleClose, changeActiveAction }) {
  const dispatch = useDispatch();

  const [reason, setReason] = useState(null);
  const [canReject, setCanReject] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleReasonChange = useCallback(
    (event) => setReason(event?.target?.value),
    []
  );

  const handleBackClick = useCallback(() => changeActiveAction("accept"), []);

  // validate reason
  useEffect(() => {
    if (reason && !helper.validator.isReason(reason))
      setErrorMessage("It should be a valid text from 30 to 100 alphanumiric");
    else setErrorMessage(null);
  }, [reason]);

  // track submission possibility
  useEffect(() => {
    if (reason && !errorMessage) setCanReject(true);
    else setCanReject(false);
  }, [reason, errorMessage]);

  const changeStatus = useCallback(() => {
    if (canReject)
      dispatch(
        actions.changeIdentityStatus({
          status: "reject",
          reason,
        })
      );
  }, [dispatch, reason, canReject]);

  return (
    <Grid container justifyContent="space-between" sx={styles.container}>
      <Grid item xs={6}>
        <Photos />
      </Grid>

      <Grid
        item
        xs={6}
        container
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <TextField
          name="reason"
          id="reason-input"
          label="Reason"
          variant="standard"
          size="small"
          onChange={handleReasonChange}
          fullWidth
          multiline
          value={reason}
          helperText={errorMessage}
          FormHelperTextProps={{ style: { color: "red" } }}
        />
        <Grid
          Item
          container
          justifyContent="space-between"
          style={{ marginTop: 60 }}
        >
          <Grid Item xs={6}>
            <IconButton onClick={handleBackClick}>
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid Item xs={6} container justifyContent="space-evenly">
            <Button
              onClick={handleClose}
              variant="contained"
              size="small"
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={changeStatus}
              variant="contained"
              size="small"
              color="error"
            >
              Confirm
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
