import { Grid, Box } from '@mui/material'

import Delete from '../delete'
import Reject from '../reject'
import Card from '../card'

import actions from '../../actions'
import selectors from '../../selectors'

import { useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import NothingFound from '../nothingFound'

const initialState = {
	openDelete: false,
	openReject: false,
}

export default function PathMais() {
	const dispatch = useDispatch()

	const pathsIds = useSelector(selectors.filteredIds)

	const [modal, setModal] = useState(initialState)

	const handleModalOpen = useCallback(
		({ type, id }) => {
			dispatch(actions.selectedSet({ id }))

			if (type === 'delete') setModal({ ...initialState, openDelete: true })
			else if (type === 'reject')
				setModal({ ...initialState, openReject: true })
		},
		[initialState, dispatch]
	)

	// get paths' ids
	useEffect(() => {
		dispatch(actions.get())
	}, [])

	const handleModalClose = useCallback(
		() => setModal(initialState),
		[initialState]
	)

	return (
		<>
			<Box sx={{ flexGrow: 1 }}>
				{pathsIds?.length ? (
					<Grid container style={{ paddingLef: 5, paddingRight: 5 }}>
						{pathsIds.map(pathsId => (
							<Grid
								key={pathsId}
								xs={3}
								style={{ marginTop: 10 }}
								container
								justifyContent='center'
								alignItems='center'
							>
								<Card id={pathsId} handleOpen={handleModalOpen} />
							</Grid>
						))}
					</Grid>
				) : (
					<NothingFound />
				)}
			</Box>
			<Delete open={modal.openDelete} handleCancel={handleModalClose} />
			<Reject open={modal.openReject} handleCancel={handleModalClose} />
		</>
	)
}
