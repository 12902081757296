import { all } from "redux-saga/effects";

import login from "./login";
import checkEmail from "./check-email";
import checkCode from "./check-code";
import resetPassword from "./reset-password";
import setup from "./setup";
import logout from "./logout";

export default function* authSaga() {
  yield all([
    login(),
    checkEmail(),
    checkCode(),
    resetPassword(),
    setup(),
    logout(),
  ]);
}
