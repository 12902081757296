import * as React from 'react'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import DeleteIcon from '@mui/icons-material/Delete'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import HourglassTopIcon from '@mui/icons-material/HourglassTop'
import { CardMedia } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import selectors from '../../selectors'
import clientActions from 'features/clients/actions'
import { useNavigate } from 'react-router-dom'

const menuId = 'primary-search-account-menu'

function ActionMenu({ anchorEl, isMenuOpen, handleMenuClose, handleOpen, id }) {
	const { isApproved, isRejected } = useSelector(state =>
		selectors.detailedOneById({ state, id })
	)
	const handleOpenRemove = React.useCallback(() => {
		handleOpen({ type: 'remove', id })
		handleMenuClose()
	}, [id, handleOpen])

	const handleOpenReject = React.useCallback(() => {
		handleOpen({ type: 'reject', id })
		handleMenuClose()
	}, [id, handleOpen])

	const handleOpenApprove = React.useCallback(() => {
		handleOpen({ type: 'approve', id })
		handleMenuClose()
	}, [handleOpen, id])

	if (!isMenuOpen) return null
	return (
		<Menu
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			id={menuId}
			keepMounted
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			open={isMenuOpen}
			onClose={handleMenuClose}
		>
			{!isApproved ? (
				<MenuItem onClick={handleOpenApprove}>
					<Button
						variant='text'
						startIcon={<CheckCircleIcon />}
						size='small'
						color='success'
					>
						Approve
					</Button>
				</MenuItem>
			) : null}
			{!isRejected ? (
				<MenuItem onClick={handleOpenReject}>
					<Button
						variant='text'
						startIcon={<CancelIcon />}
						size='small'
						color='error'
					>
						Reject
					</Button>
				</MenuItem>
			) : null}
			<MenuItem onClick={handleOpenRemove}>
				<Button
					variant='text'
					startIcon={<DeleteIcon />}
					size='small'
					color='error'
				>
					Delete
				</Button>
			</MenuItem>
		</Menu>
	)
}

export default function PaymentsCard({ id, handleOpen = () => {} }) {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const payment = useSelector(state => selectors.detailedOneById({ state, id }))
	const [anchorEl, setAnchorEl] = React.useState(null)
	const isMenuOpen = Boolean(anchorEl)

	const handleMenuOpen = event => {
		setAnchorEl(event.currentTarget)
	}
	const handleMenuClose = () => {
		setAnchorEl(null)
	}
	const handleGotoCreator = React.useCallback(() => {
		dispatch(clientActions.selectedSet({ id: payment.creator?.id }))
		navigate('/dashboard/clients/detailed')
	}, [navigate, dispatch, payment])

	if (!payment) return null
	return (
		<>
			<Card sx={{ width: '95%' }}>
				<CardHeader
					avatar={
						<Button onClick={handleGotoCreator}>
							<Avatar
								src={payment?.creator?.photo}
								sx={{ width: 50, height: 50 }}
							/>
						</Button>
					}
					action={
						<IconButton aria-controls={menuId} onClick={handleMenuOpen}>
							{payment.isApproved ? (
								<CheckCircleIcon color={'success'} />
							) : payment.isRejected ? (
								<CancelIcon color={'error'} />
							) : (
								<HourglassTopIcon color='warning' />
							)}
						</IconButton>
					}
					title={payment?.creator?.fullName}
					subheader={
						<Typography variant='caption'>{payment?.createdAt}</Typography>
					}
				/>

				<CardMedia
					component='img'
					height='150'
					src={`data:image/png;base64,${payment.photo}`}
				/>
			</Card>
			<ActionMenu
				handleOpen={handleOpen}
				anchorEl={anchorEl}
				isMenuOpen={isMenuOpen}
				handleMenuClose={handleMenuClose}
				id={id}
			/>
		</>
	)
}
