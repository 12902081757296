import avatar from "./avatar.png";

const user = {
  name: "Sanaa Salihi",
  email: "sanaa@gmail.com",
  CIN: "QED123",
  profilePicture: avatar,
  phoneNumber: "06 123-456-7890",
};

export default user;
