import { put, select } from "redux-saga/effects";

import actions from "../../actions";
import selectors from "../../selectors";

export default function* changeProfileStatusWorker({payload = {}, meta = {}}) {
  const userId = yield select(selectors.selected);

 yield put(actions.update({ userId, profileStatus: payload.status, reason: payload.reason, meta }));
}
