const EXPEDITION = "expedition ::: ";

export default Object.freeze({
    // for middleware
    remove: `${EXPEDITION}remove`,

  // for saga
  get: `${EXPEDITION}get`,
  changeStatus: `${EXPEDITION}change/status`,
  hardDelete: `${EXPEDITION}hard/delete`,

  // for reducer
  merged: `${EXPEDITION}merged`,
  updated: `${EXPEDITION}updated`,
  selectedSet: `${EXPEDITION}selected/set`,
  filterUpdated: `${EXPEDITION}filter/updated`,
  removed: `${EXPEDITION}removed`,
});
