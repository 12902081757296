const CLIENT = "client ::: ";

export default Object.freeze({
  // for middleware
  remove: `${CLIENT}remove`,
  get: `${CLIENT}get`,  
  changeIdentityStatus: `${CLIENT}change/identity/status`,
  changeProfileStatus: `${CLIENT}change/profile/status`,
  
  // for saga
  getMany: `${CLIENT}get/many`,
  getOne: `${CLIENT}get/one`,
  hardDelete: `${CLIENT}hard/delete`,
  changeStatus: `${CLIENT}change/status`,
  update: `${CLIENT}update`,

  // for reducer
  merged: `${CLIENT}merged`,
  removed: `${CLIENT}removed`,
  updated: `${CLIENT}updated`,
  selectedSet: `${CLIENT}selected/set`,
  filterUpdated: `${CLIENT}filter/updated`,
});