// for saga watchers
import get from "./get";
import getOne from "./getOne";
import getMany from "./getMany";
import changeStatus from "./change-status";
import remove from "./remove";
import hardDelete from "./hardDelete";

// for reducer
import merged from "./merged";
import statusChanged from "./status-changed";
import selectedSet from "./selected-set";
import removed from "./removed";
import filterUpdated from "./filter-updated";

export default Object.freeze({
  get,
  getOne,
  getMany,
  changeStatus,
  remove,
  hardDelete,
  statusChanged,
  merged,
  filterUpdated,
  selectedSet,
  removed,
});
