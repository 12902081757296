import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";



export const EditPasswordButton = styled(IconButton)({
    color: "#267DC9",
    fontSize: "0.95rem",
    backgroundColor: "#E5F3FF",
    borderRadius: "360px",
    padding: "8px",
    "&:hover": {
      backgroundColor: "#F4FBFF",
    },
    "& .MuiSvgIcon-root": {
      marginLeft: "10px", // Ajoutez une marge à droite à l'icône
    },
  });
  