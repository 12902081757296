import {
	Avatar,
	Badge,
	Button,
	Divider,
	Grid,
	IconButton,
	Stack,
	Switch,
	Typography,
} from '@mui/material'
import PhoneIcon from '@mui/icons-material/Phone'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import PublicIcon from '@mui/icons-material/Public'
import HourglassTopIcon from '@mui/icons-material/HourglassTop'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import DeleteIcon from '@mui/icons-material/Delete'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import TimerOffIcon from '@mui/icons-material/TimerOff'
import { Edit as EditIcon } from '@mui/icons-material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import useDetailedClient from './service'
import Photos from '../identity/photos'
import Profile from '../profile'
import Identity from '../identity'
import Delete from '../delete'
import Deactivate from '../deactivate'

function renderStatusText({ color = 'orange', text = 'Inreview' } = {}) {
	return (
		<Typography variant='body2' style={{ marginLeft: 5 }} color={color}>
			{text}
		</Typography>
	)
}

function RenderComplexStatus({
	isInreview,
	isVerified,
	isExpired = false,
	isExist,
}) {
	return (
		<>
			{isExist ? (
				isExpired ? (
					<>
						<TimerOffIcon fontSize='small' color='error' />
						{renderStatusText({ color: 'red', text: 'Expired' })}
					</>
				) : isVerified ? (
					<>
						<CheckCircleIcon fontSize='small' color='success' />
						{renderStatusText({ color: 'green', text: 'Verified' })}
					</>
				) : isInreview ? (
					<>
						<HourglassTopIcon fontSize='small' color='warning' />
						{renderStatusText()}
					</>
				) : (
					<>
						<CancelIcon fontSize='small' color='error' />
						{renderStatusText({ color: 'red', text: 'Rejected' })}
					</>
				)
			) : (
				<>
					<ErrorOutlineIcon fontSize='small' color='error' />
					{renderStatusText({ color: 'red', text: 'Unverified' })}
				</>
			)}
		</>
	)
}

function RenderSimpleStatus({ isVerified }) {
	return (
		<>
			{isVerified ? (
				<>
					<CheckCircleIcon fontSize='small' color='success' />
					{renderStatusText({ color: 'green', text: 'Verified' })}
				</>
			) : (
				<>
					<ErrorOutlineIcon fontSize='small' color='error' />
					{renderStatusText({ color: 'red', text: 'Unverified' })}
				</>
			)}
		</>
	)
}

function RenderAccountStatus({ isInreview, isVerified }) {
	return (
		<>
			{isInreview ? (
				<>
					<HourglassTopIcon fontSize='small' color='warning' />
					{renderStatusText()}
				</>
			) : isVerified ? (
				<>
					<CheckCircleIcon fontSize='small' color='success' />
					{renderStatusText({ color: 'green', text: 'Verified' })}
				</>
			) : (
				<>
					<ErrorOutlineIcon fontSize='small' color='error' />
					{renderStatusText({ color: 'red', text: 'Unverified' })}
				</>
			)}
		</>
	)
}

function RenderReason({ reason }) {
	return (
		<Grid item container style={{ marginTop: 25, marginBottom: 25 }} flexDirection={'column'}>
			{reason && (
				<>
					<Typography variant='body2' color='text.secondary'>
						Reason:
					</Typography>
					<Typography variant='body2' color='red'>
						{reason}
					</Typography>
				</>
			)}
		</Grid>
	)
}

export default function Client() {
	const {
		client,
		handleModalOpen,
		handleModalClose,
		modal,
		handleActivitiStatusChnage,
		handleGoBack,
	} = useDetailedClient()

	return (
		<>
			<Button
				size='small'
				startIcon={<ArrowBackIcon />}
				style={{ marginTop: 15, marginLeft: 15, color: 'gray' }}
				onClick={handleGoBack}
			>
				Go Back
			</Button>
			{ client ?
				<Grid
					container
					style={{
						border: 1,
						borderColor: 'gray',
						marginLeft: 50,
						marginRight: 50,
						padding: 20,
					}}
				>
					{/** left section */}
					<Grid item container xs={4}>
						{/** balance */}
						<Grid item container flexDirection='row' alignItems='center'>
							<Typography variant='body2' fontWeight={700} color='blue'>
								Balance:
							</Typography>
							<Typography
								variant='body2'
								color='blue'
								style={{ marginLeft: 20 }}
								fontWeight={700}
							>
								{client?.balance}
							</Typography>
						</Grid>
						{/** Account verification status */}
						<Grid
							item
							container
							flexDirection='row'
							alignItems='center'
							style={{ marginTop: 10 }}
						>
							<Typography
								variant='body2'
								color='text.secondary'
								fontWeight={700}
								style={{ marginRight: 80 }}
							>
								Account is:
							</Typography>
							<RenderAccountStatus
								isVerified={client?.account?.isVerified}
								isInreview={client?.account?.isInreview}
							/>
						</Grid>
						{/** activity status */}
						<Grid
							item
							container
							flexDirection='row'
							alignItems='center'
							style={{ marginTop: 10 }}
						>
							<Typography
								variant='body2'
								color='text.secondary'
								fontWeight={700}
								style={{ marginRight: 80 }}
							>
								Account is:
							</Typography>
							<Stack direction='row' spacing={1} alignItems='center'>
								<Typography variant='caption' color='GrayText'>
									Inactive
								</Typography>
								<Switch
									checked={client?.account?.isActive}
									color={client?.account?.isActive ? 'success' : 'default'}
									onChange={handleActivitiStatusChnage}
								/>
								<Typography variant='caption' color='GrayText'>
									Active
								</Typography>
							</Stack>
						</Grid>

						<Divider style={{ width: '70%', marginBottom: 25, marginTop: 25 }} />

						{/** delete user */}
						<Grid item container flexDirection={'row'} alignItems={'center'}>
							<Typography
								variant='body2'
								color='red'
								fontWeight={700}
								style={{ marginRight: 120 }}
							>
								Delete Client:
							</Typography>
							<IconButton
								size='small'
								color='error'
								variant='outlined'
								onClick={() => handleModalOpen({ type: 'delete' })}
							>
								<DeleteIcon />
							</IconButton>
						</Grid>

						<Divider style={{ width: '70%', marginBottom: 25, marginTop: 25 }} />

						{/** profile photo */}
						<Avatar
							src={client?.profile?.photo}
							sx={{ width: 150, height: 150 }}
						/>
						{/** profile photo verification status */}
						<Grid
							item
							container
							flexDirection='row'
							alignItems='center'
							style={{ marginTop: 25 }}
						>
							<Typography
								variant='body2'
								color='text.secondary'
								fontWeight={700}
								style={{ marginRight: 80 }}
							>
								Profile is:
							</Typography>
							<RenderComplexStatus
								isExist={client?.profile?.photo}
								isInreview={client?.profile?.isInreview}
								isVerified={client?.profile?.isAccepted}
							/>
							<IconButton
								size='small'
								style={{ marginLeft: 20 }}
								onClick={() =>
									handleModalOpen({
										type: 'profile',
										open: !client?.profile?.isAccepted && client?.profile?.photo,
									})
								}
							>
								<EditIcon />
							</IconButton>
						</Grid>
						{/** reason if profile is rejected */}
						<RenderReason reason={client?.profile?.reason} />
					</Grid>
					{/** middle section */}
					<Grid item container xs={4}>
						{/** full name */}
						<Grid item container flexDirection='row' alignItems='center'>
							<Typography variant='body2' color='text.secondary' fontWeight={700}>
								Full name:
							</Typography>
							<Typography
								variant='body2'
								color='text.secondary'
								style={{ marginLeft: 20 }}
							>
								{client?.fullName}
							</Typography>
						</Grid>
						{/** username */}
						<Grid
							item
							container
							flexDirection='row'
							alignItems='center'
							style={{ marginTop: 10 }}
						>
							<Typography variant='body2' color='text.secondary' fontWeight={700}>
								Username:
							</Typography>
							<Typography
								variant='body2'
								color='text.secondary'
								style={{ marginLeft: 20 }}
							>
								{client?.username}
							</Typography>
						</Grid>
						{/** gender */}
						<Grid
							item
							container
							flexDirection='row'
							alignItems='center'
							style={{ marginTop: 10 }}
						>
							<Typography variant='body2' color='text.secondary' fontWeight={700}>
								Gender:
							</Typography>
							<Typography
								variant='body2'
								color='text.secondary'
								style={{ marginLeft: 20 }}
							>
								{client?.gender}
							</Typography>
						</Grid>
						{/** Birthday */}
						<Grid
							item
							container
							flexDirection='row'
							alignItems='center'
							style={{ marginTop: 10 }}
						>
							<Typography variant='body2' color='text.secondary' fontWeight={700}>
								Birthday:
							</Typography>
							<Typography
								variant='body2'
								color='text.secondary'
								style={{ marginLeft: 20 }}
							>
								{client?.birthday}
							</Typography>
						</Grid>
						{/** language */}
						<Grid
							item
							container
							flexDirection='row'
							alignItems='center'
							style={{ marginTop: 10 }}
						>
							<Typography variant='body2' color='text.secondary' fontWeight={700}>
								Language:
							</Typography>
							<Typography
								variant='body2'
								color='text.secondary'
								style={{ marginLeft: 20 }}
							>
								{client?.language}
							</Typography>
						</Grid>
						<Divider style={{ width: '80%', marginBottom: 25, marginTop: 25 }} />
						{/** phone */}
						<Grid item container flexDirection='row' alignItems='center'>
							<Badge
								variant={client?.phone?.value ? 'dot' : 'standard'}
								color={client?.phone?.isVerified ? 'success' : 'error'}
							>
								<PhoneIcon color='action' />
							</Badge>
							{client?.phone?.value ? (
								<Typography
									variant='body2'
									color='text.secondary'
									style={{ marginLeft: 20, marginRight: 40 }}
								>
									{client?.phone?.value}
								</Typography>
							) : (
								<Typography
									variant='body2'
									color='red'
									style={{ marginLeft: 20, marginRight: 20 }}
								>
									Phone is missing !
								</Typography>
							)}
							<RenderSimpleStatus isVerified={client?.phone?.isVerified} />
						</Grid>
						{/** email */}
						<Grid
							container
							flexDirection='row'
							alignItems='center'
							style={{ marginTop: 10 }}
						>
							<Badge
								variant={client?.email?.value ? 'dot' : 'standard'}
								color={client?.email?.isVerified ? 'success' : 'error'}
							>
								<AlternateEmailIcon color='action' />
							</Badge>
							{client?.email?.value ? (
								<Typography
									variant='body2'
									color='text.secondary'
									style={{ marginLeft: 20, marginRight: 20 }}
								>
									{client?.email?.value}
								</Typography>
							) : (
								<Typography
									variant='body2'
									color='red'
									style={{ marginLeft: 20, marginRight: 40 }}
								>
									Email is missing !
								</Typography>
							)}
							<RenderSimpleStatus isVerified={client?.email?.isVerified} />
						</Grid>

						{client?.nationality && (
							<Grid
								container
								flexDirection='row'
								alignItems='center'
								style={{ marginTop: 10 }}
							>
								<PublicIcon color='action' />
								<Typography
									variant='body2'
									color='text.secondary'
									style={{ marginLeft: 20 }}
								>
									{client?.nationality}
								</Typography>
							</Grid>
						)}
						<Divider style={{ width: '80%', marginBottom: 25, marginTop: 25 }} />
						{/** created at */}
						<Grid container flexDirection='row' alignItems='center'>
							<Typography variant='body2' color='text.secondary' fontWeight={700}>
								Created at:
							</Typography>
							<Typography
								variant='body2'
								color='text.secondary'
								style={{ marginLeft: 20 }}
							>
								{client?.createdAt}
							</Typography>
						</Grid>
						{/** last login time */}
						<Grid
							item
							container
							flexDirection='row'
							alignItems='center'
							style={{ marginTop: 10 }}
						>
							<Typography variant='body2' color='text.secondary' fontWeight={700}>
								Last Login:
							</Typography>
							<Typography
								variant='body2'
								color='text.secondary'
								style={{ marginLeft: 20 }}
							>
								{client?.lastLoginAt}
							</Typography>
						</Grid>
					</Grid>
					{/** right section */}
					<Grid item container xs={4}>
						<Photos />
						{/** status */}
						<Grid
							item
							container
							flexDirection='row'
							alignItems='center'
							style={{ marginTop: 25 }}
						>
							<Typography
								variant='body2'
								color='text.secondary'
								fontWeight={700}
								style={{ marginRight: 80 }}
							>
								Identity is:
							</Typography>
							<RenderComplexStatus
								isExist={client?.identity?.isExist}
								isVerified={client?.identity?.isAccepted}
								isInreview={client?.identity?.isInreview}
								isExpired={client?.identity?.isExpired}
							/>
							<IconButton
								size='small'
								style={{ marginLeft: 20 }}
								onClick={() =>
									handleModalOpen({
										type: 'identity',
										open:
											client?.identity?.values?.length === 2 &&
											!client?.identity?.isAccepted,
									})
								}
							>
								<EditIcon />
							</IconButton>
						</Grid>

						{/** identity reason if rejected */}
						<RenderReason reason={client?.identity?.reason} />

						{(client?.identity?.type ||
							client?.identity?.code ||
							client?.identity?.expiresAt) && (
							<Divider style={{ width: '70%', marginBottom: 25 }} />
						)}

						{/** identity type */}
						{client.identity?.type && (
							<Grid
								item
								container
								flexDirection='row'
								alignItems='center'
								style={{ marginTop: 25 }}
							>
								<Typography
									variant='body2'
									color='text.secondary'
									fontWeight={700}
								>
									Identity Type:
								</Typography>
								<Typography
									variant='body2'
									color='GrayText'
									style={{ marginLeft: 20 }}
								>
									{client?.identity?.type}
								</Typography>
							</Grid>
						)}
						{/** identity code */}
						{client.identity?.code && (
							<Grid item container flexDirection='row' alignItems='center'>
								<Typography
									variant='body2'
									color='text.secondary'
									fontWeight={700}
								>
									Identity Code:
								</Typography>
								<Typography
									variant='body2'
									color='GrayText'
									style={{ marginLeft: 20 }}
								>
									{client?.identity?.code}
								</Typography>
							</Grid>
						)}
						{/** Expiration date */}
						{client.identity?.expiresAt && (
							<Grid item container flexDirection='row' alignItems='center'>
								<Typography
									variant='body2'
									color='text.secondary'
									fontWeight={700}
								>
									Expires At:
								</Typography>
								<Typography
									variant='body2'
									color='GrayText'
									style={{ marginLeft: 20 }}
								>
									{client?.identity?.expiresAt}
								</Typography>
							</Grid>
						)}
					</Grid>
				</Grid>
			: null }
			<Profile open={modal.openProfile} handleClose={handleModalClose} />
			<Identity open={modal.openIdentity} handleClose={handleModalClose} />
			<Delete open={modal.openDelete} handleCancel={handleModalClose} />
			<Deactivate open={modal.openDeacivate} handleCancel={handleModalClose} />
		</>
	)
}
