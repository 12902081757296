import { normalize, schema } from "normalizr";

// user schema
const user = new schema.Entity("users");

// path schema
const path = new schema.Entity("paths", {
  creatorId: user,
});

const pathsListSchema = new schema.Array(path);

export default function normalizer(data) {
  const normalizedData = normalize(data, pathsListSchema);
  return {
    paths: normalizedData.entities["paths"],
    users: normalizedData.entities["users"],
  };
}
