/**
 * @filter_format
 * {
 *  status:
 *      inreview
 *      rejected
 *      approved
 *  creatorId, capacity, vehicleType, from, to
 * }
 */

import types from "../../actionsTypes";

export default function filterUpdated({
  status,
  creatorId,
  capacity,
  vehicleType,
  from,
  to,
}) {
  let payload = {};

  if (status) payload["status"] = status;
  if (creatorId) payload["creatorId"] = creatorId;
  if (capacity) payload["capacity"] = capacity;
  if (vehicleType) payload["vehicleType"] = vehicleType;
  if (from) payload["from"] = from;
  if (to) payload["to"] = to;

  return {
    type: types.filterUpdated,
    payload,
  };
}
