import mockingData from './mockingData'
import helpers from 'helpers'

const emptyState = {
	all: {},
	selected: null,
	filter: {
		userId: undefined, // memory helper
		limit: 12,
		page: 1,
		status: 'inactive', // temp, TODO: delete it, cuz it will be set in filterUpdated action
	},
}

const mockingState = {
	...emptyState,
	all: mockingData,
}

export default helpers.initReduxState({ emptyState, mockingState })
