import ChangePassword from "../changePassword";
import ChangePhoneNumber from "../changePhoneNumber";
import Header from "../header/header";
import useMain from "./service";
import { Grid, Snackbar } from "@mui/material";

export function Main() {
  const { isShowNotif, showNotif, message, closeNotif } = useMain();

  return (
    <Grid container sx={{ marginTop: "70px" }}>
      {/* header */}
      <Grid item container alignItems={"center"} flexDirection={"column"}>
        <Header />
      </Grid>

      <Grid item container>
        {/* Numéro de téléphone */}
        <Grid
          item
          container
          xs={6}
          spacing={2}
          alignItems={"center"}
          flexDirection={"column"}
        >
          <ChangePhoneNumber showNotif={showNotif} />
        </Grid>

        {/* password*/}
        <Grid
          item
          container
          xs={6}
          spacing={2}
          alignItems={"center"}
          flexDirection={"column"}
        >
          <ChangePassword showNotif={showNotif} />
        </Grid>
      </Grid>

      <Snackbar
        open={isShowNotif}
        autoHideDuration={3000}
        onClose={closeNotif}
        message={message}
      />
    </Grid>
  );
}
