import { Grid } from '@mui/material'

import Delete from '../remove'
import Reject from '../reject'
import Approve from "../approve"
import Card from '../card'

import useMain from './service'

export default function PaymentsMain() {
	const { ids, isOpenModal, handleModalOpen, handleModalClose } = useMain()

	return (
		<>
			{ids.map(id => (
				<Grid
					item
					key={id}
					xs={3}
					style={{ marginTop: 10 }}
					container
					justifyContent='center'
					alignItems='center'
				>
					<Card id={id} handleOpen={handleModalOpen} />
				</Grid>
			))}
			<Delete open={isOpenModal.remove} handleCancel={handleModalClose} />
			<Reject open={isOpenModal.reject} handleCancel={handleModalClose} />
			<Approve open={isOpenModal.approve} handleCancel={handleModalClose} />
		</>
	)
}
