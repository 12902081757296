import { useEffect, useState } from 'react'

const usePassword = showNotif => {
	const [currentPassword, setCurrentPassword] = useState('')

	const [newPassword, setNewPassword] = useState('')
	const [confirmNewPassword, setConfirmNewPassword] = useState('')
	const [isEditingPassword, setIsEditingPassword] = useState(false)
	const [passwordConfirmationError, setPasswordConfirmationError] = useState('')
	const [isCurrentPasswordVisible, setIsCurrentPasswordVisible] =
		useState(false)
	const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false)
	const [isConfirmNewPasswordVisible, setIsConfirmNewPasswordVisible] =
		useState(false)

	const [localErrors, setLocalErrors] = useState({
		newPassword: null,
		passwordConfirmation: null,
	})

	// new password validation
	useEffect(() => {
		if (newPassword.length > 0 && newPassword.length < 5) {
			setLocalErrors({
				localErrors,
				newPassword: 'Le mot de passe doit comporter au moins 5 caractères.',
			})
		} else {
			setLocalErrors({
				localErrors,
				newPassword: null,
			})
		}
	}, [newPassword])

	//  confirmation password validation
	useEffect(() => {
		if (newPassword !== confirmNewPassword) {
			setLocalErrors({
				...localErrors,
				passwordConfirmation: 'Les mots de passe ne correspondent pas.',
			})
		} else {
			setLocalErrors({
				...localErrors,
				passwordConfirmation: null,
			})
		}
	}, [confirmNewPassword])

	const handleEditPassword = () => setIsEditingPassword(true)

	const handleSavePassword = () => {
		if (
			currentPassword === '' ||
			newPassword === '' ||
			confirmNewPassword === ''
		) {
			setPasswordConfirmationError('Please fill in all the fields.')
		} else if (newPassword !== confirmNewPassword) {
			setPasswordConfirmationError(
				'The confirmation password does not match the new password.'
			)
		} else {
			setIsEditingPassword(false)
			setPasswordConfirmationError('')

			showNotif('New Password saved !')
		}
	}

	const handleCancelPasswordEdit = () => {
		setIsEditingPassword(false)
		setPasswordConfirmationError('')
		setCurrentPassword('')
		setNewPassword('')
		setConfirmNewPassword('')
	}

	const handleCurrentPasswordChange = event =>
		setCurrentPassword(event.target.value)

	const handleNewPasswordChange = event => setNewPassword(event.target.value)

	const handleConfirmNewPasswordChange = event =>
		setConfirmNewPassword(event.target.value)

	return {
		errors: localErrors,
		isErrors: {
			newPassword: localErrors.newPassword ? true : false,
			passwordConfirmation: localErrors.passwordConfirmation ? true : false,
		},
		currentPassword,
		newPassword,
		confirmNewPassword,
		isEditingPassword,
		passwordConfirmationError,
		handleEditPassword,
		handleSavePassword,
		handleCancelPasswordEdit,
		handleCurrentPasswordChange,
		handleNewPasswordChange,
		handleConfirmNewPasswordChange,
		isCurrentPasswordVisible,
		setIsCurrentPasswordVisible,
		isNewPasswordVisible,
		setIsNewPasswordVisible,
		isConfirmNewPasswordVisible,
		setIsConfirmNewPasswordVisible,
	}
}

export default usePassword
