import { normalize, schema } from "normalizr";

// user schema
const client = new schema.Entity("clients");

// receiver schema
const receiver = new schema.Entity("receivers", {
  creator: client
});

const receiversListSchema = new schema.Array(receiver);

export default function many(data) {
  const normalizedData = normalize(data, receiversListSchema);

  return {
    clients: normalizedData.entities["clients"],
    receivers: normalizedData.entities["receivers"],
  };
}
