import Components from "components";
import { useDispatch } from "react-redux";
import actions from "../../actions";
import { useCallback, useEffect, useState } from "react";
import { Grid, TextField, Button } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import helper from "helpers";
import styles from "./styles";

function Content({handleClose}){
    const dispatch = useDispatch();

    const [amount, setAmount] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [canAccept, setCanAccept] = useState(false);

    const handleAmountChange = useCallback((event) =>
    setAmount(event?.target?.value)
    );

    // validate amount
    useEffect(() => {
        if (amount && !helper.validator.isNumber(Number(amount)))
        setErrorMessage("Provided value is invalid");
        else setErrorMessage(null);
    }, [amount]);

    // track submission possibility
    useEffect(() => {
        if (amount && !errorMessage) setCanAccept(true);
        else setCanAccept(false);
    }, [amount, errorMessage]);

    const changeStatus = useCallback(() => {
        if (canAccept)
          dispatch(actions.changeStatus({ status: 'approved', amount: Number(amount) }))
    }, [dispatch, canAccept, amount]);

    return (
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            sx={styles.container}
          >
            <TextField
              name="amount"
              id="amount"
              label="Amount"
              variant="standard"
              size="small"
              style={{ width: "70%" }}
              value={amount}
              helperText={errorMessage}
              FormHelperTextProps={{ style: { color: "red" } }}
              onChange={handleAmountChange}
            />
            <Grid
              Item
              container
              justifyContent="space-evenly"
              style={{ marginTop: 60 }}
            >
                <Button
                  onClick={handleClose}
                  variant="contained"
                  size="small"
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  onClick={changeStatus}
                  startIcon={<CheckIcon />}
                  variant="contained"
                  size="small"
                  color="success"
                  disabled={!canAccept}
                >
                  Confirm
                </Button>
            </Grid>
          </Grid>
      );
}

export default function Approve({ open, handleClose }) {
  return (
    <Components.Modal
      open={open}
      handleClose={handleClose}
      renderContent={
          <Content
            handleClose={handleClose}
          />
      }
    />
  );
}
