import { all } from "redux-saga/effects";

import getMany from "./getMany";
import getOne from "./getOne";
import changeStatus from "./changeStatus";
import changeProfileStatus from "./changeProfileStatus";
import changeIdentityStatus from "./changeIdentityStatus";
import hardDelete from "./hardDelete";

import get from "./get";
import remove from "./remove";
import update from "./update";

export default function* authSaga() {
  yield all([
    get(),
    update(),
    remove(),
    getMany(),
    getOne(),
    changeStatus(),
    changeProfileStatus(),
    changeIdentityStatus(),
    hardDelete(),
  ]);
}
