import { put, select } from "redux-saga/effects";

import actions from "../../actions";
import selectors from "../../selectors";

export default function* changeIdentityStatusWorker({payload = {}, meta= {}}) {
  const userId = yield select(selectors.selected);
  const {status, ...rest} = payload;

 yield put(actions.update({
    meta,
    userId,
    identityStatus: payload.status,
    ...rest
  }));
}
