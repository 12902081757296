import { useDispatch, useSelector } from 'react-redux'
import selectors from '../../selectors'
import { useCallback, useEffect, useState } from 'react'
import actions from '../../actions'
import { useNavigate } from 'react-router-dom'
import types from "../../actionsTypes"
import errors from "store/errors";

const initialState = {
	openProfile: false,
	openIdentity: false,
	openDelete: false,
	openDeacivate: false,
}

export default function useDetailedClient() {
	const dispatch = useDispatch()
	const client = useSelector(selectors.detailedSelected)
	const navigate = useNavigate()

	const [modal, setModal] = useState(initialState)

	const handleModalOpen = useCallback(
		({ type, open }) => {
			if (type === 'identity')
				setModal({ ...initialState, openIdentity: true && open })
			else if (type === 'profile')
				setModal({ ...initialState, openProfile: true && open })
			else if (type === 'delete')
				setModal({ ...initialState, openDelete: true })
			else if (type === 'deactivate')
				setModal({ ...initialState, openDeacivate: true })
		},
		[initialState, dispatch]
	)

	const handleModalClose = useCallback(
		() => setModal(initialState),
		[initialState]
	)

	const handleActivitiStatusChnage = useCallback(
		e => {
			if (client?.account?.isActive) handleModalOpen({ type: 'deactivate' })
			else dispatch(actions.changeStatus({ id: client?.id, status: 'active' }))
		},
		[handleModalOpen, client, dispatch]
	)

	const handleGoBack = useCallback(
		() => navigate(-1),
		[navigate]
	)

	// handle delete success -> redirect the user to the clients list
	const { isSuccess, id } = useSelector(errors.selectors.error)

	useEffect(() => {
		if (isSuccess && id === types.remove) navigate('/dashboard/clients')
	}, [isSuccess, id, navigate])


	return {
		client,
		handleModalOpen,
		handleModalClose,
		modal,
		handleActivitiStatusChnage,
		handleGoBack,
	}
}
