import { useState } from "react";

export default function useMain() {
  const [isShowNotif, setIsHowNotif] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const showNotif = (message) => {
    setIsHowNotif(true);
    setSnackbarMessage(message);
  };

  const closeNotif = () => {
    setIsHowNotif(false);
    setSnackbarMessage("");
  };

  return {
    isShowNotif,
    showNotif,
    message: snackbarMessage,
    closeNotif,
  };
}
