import { put, select } from "redux-saga/effects";

import errors from "store/errors";
import pathsSelectors from "../../selectors";
import normalizer from "../../normalizer";
import helpers from "helpers";
import config from "../../../../config.json";
import expeditionsActions from "../../actions"
import receiversActions from "features/receivers/actions"
import clientsActions from  "features/clients/actions"
import  {batchActions} from  "redux-batched-actions"
 
export default function* get({ meta = {} }) {
  const filter = yield select(pathsSelectors.filter);

  let actions = [];

  let { data } = yield helpers.sagas.worker({
    url: config.endpoints.expeditions.root,
    params: { filter },
    loadingId: meta.id,
  });

  if (data?.success && data?.found) {
    const normalized = normalizer(data?.found);

    actions.push(expeditionsActions.merged({expeditions: normalized.expeditions}))
    actions.push(receiversActions.merged({receivers: normalized.receivers}));
    actions.push(clientsActions.merged({clients: normalized.users}));
    
    actions.push(errors.actions.updated({ isSuccess: true, id: meta.id }));

    yield put(batchActions(actions));
  }
}
