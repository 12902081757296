import { put, select } from "redux-saga/effects";

import receiverActions from "../../actions";
import authSelectors from "features/auth/selectors";

export default function* removeWorker({type}) {
  const { role } = yield select(authSelectors.user);

  if (role === "admin")
    yield put(
      receiverActions.changeStatus({ status: "deleted", meta: {id: type} })
    );
  else yield put(receiverActions.hardDelete());
}
