import types from "../../actions-types";

export default function merged({ receivers = {} }) {
  return {
    type: types.merged,
    payload: {
      receivers,
    },
  };
}
