const PATH = "path ::: ";

export default Object.freeze({
  // for middleware
  remove: `${PATH}remove`,

  // for saga
  get: `${PATH}get`,
  changeStatus: `${PATH}change/status`,
  hardDelete: `${PATH}hard/delete`,


  // for reducer
  merged: `${PATH}merged`,
  updated: `${PATH}updated`,
  removed: `${PATH}removed`,
  selectedSet: `${PATH}selected/set`,
  filterUpdated: `${PATH}filter/updated`,
});
