import filter from "./filter"; // used by get-one-by-filter saga
import detailedSelected from "./detailed-selected"; // used by receiver page
import detailedOneById from "./detailed-one-by-id"; // used by expedition page & receiver card
import filteredIds from "./filtered-ids"; // used in main page
import selected from "./selected";

export default Object.freeze({
  filter,
  detailedSelected,
  detailedOneById,
  filteredIds,
  selected
});
