import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { Badge, Grid } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import DepartureBoardIcon from "@mui/icons-material/DepartureBoard";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import { CardMedia } from "@mui/material";
import FormatSizeIcon from "@mui/icons-material/FormatSize";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { useDispatch, useSelector } from "react-redux";
import selectors from "../../selectors";
import actions from "../../actions";
import clientActions from 'features/clients/actions'
import { useNavigate } from 'react-router-dom'

const menuId = "change-expedition-status-menu";

export default function ExpeditionCard({ id, handleOpen }) {
  const dispatch = useDispatch();
	const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const expedition = useSelector((state) =>
    selectors.detailedOneById({ id, state })
  );

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleApprove = React.useCallback(() => {
    dispatch(actions.changeStatus({ id, status: "approved" }));
    handleMenuClose();
  }, [dispatch, id]);

  const handleReject = React.useCallback(() => {
    handleOpen({ type: "reject", id });
    handleMenuClose();
  }, []);

  const handleDelete = React.useCallback(() => {
    handleOpen({ type: "delete", id });
    handleMenuClose();
  }, []);

  const handleGotoCreator = React.useCallback(() => {
		dispatch(clientActions.selectedSet({ id: expedition?.creator?.id}))
		navigate('/dashboard/clients/detailed')
	}, [navigate, dispatch, expedition])


  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {!expedition?.isApproved && (
        <MenuItem onClick={handleApprove}>
          <Button
            variant="text"
            startIcon={<CheckCircleIcon />}
            size="small"
            color="success"
          >
            Approve
          </Button>
        </MenuItem>
      )}
      {!expedition?.isRejected && (
        <MenuItem onClick={handleReject}>
          <Button
            variant="text"
            startIcon={<CancelIcon />}
            size="small"
            color="error"
          >
            Reject
          </Button>
        </MenuItem>
      )}
      {!expedition?.isDeleted && (
        <MenuItem onClick={handleDelete}>
          <Button
            variant="text"
            startIcon={<DeleteIcon />}
            size="small"
            color="error"
          >
            Delete
          </Button>
        </MenuItem>
      )}
    </Menu>
  );

  return (
    <>
      <Card sx={{ width: "95%" }}>
        <CardHeader
          avatar={
            <Badge
              variant={
                expedition?.creator?.profile ||
                expedition?.creator?.isProfileValid ||
                expedition?.creator?.isProfileInreview
                  ? "dot"
                  : "standard"
              }
              color={
                expedition?.creator?.isProfileInreview
                  ? "warning"
                  : expedition?.creator?.isProfileValid
                  ? "success"
                  : "error"
              }
            >
              <Button onClick={handleGotoCreator}>
                {expedition?.creator?.profile ? (
                  <Avatar>
                    <img
                      src={expedition?.creator?.profile}
                      alt={`expedition-creator-profile-${expedition?.creator?.profile}`}
                      style={{ width: 50, height: 50 }}
                    />
                  </Avatar>
                ) : (
                  <Avatar />
                )}
              </Button>
            </Badge>
          }
          action={
            <IconButton aria-controls={menuId} onClick={handleProfileMenuOpen}>
              {expedition?.status === "approved" ? (
                <CheckCircleIcon color={"success"} />
              ) : expedition?.status === "rejected" ? (
                <CancelIcon color={"error"} />
              ) : (
                <HourglassTopIcon color="warning" />
              )}
            </IconButton>
          }
          title={
            <Grid
              container
              flexDirection="row"
              alignItems="center"
              justifyContent="space-around"
            >
              <Typography variant="caption">{expedition?.from}</Typography>
              <ArrowRightAltIcon />
              <Typography variant="caption">{expedition?.to}</Typography>
            </Grid>
          }
          subheader={
            <Grid container flexDirection="row" alignItems="center">
              <DepartureBoardIcon fontSize="small" />
              <Typography variant="caption" style={{ marginLeft: 15 }}>
                {expedition?.startedAt}
              </Typography>
            </Grid>
          }
        />

        <CardMedia
          component="img"
          height="150"
          src={`data:image/png;base64,${expedition?.photos[0]}`}
        />

        <CardContent>
          <Grid container alignItems="center" style={{ marginTop: 10 }}>
            <Grid item xs={6} container flexDirection="row" alignItems="center">
              {expedition?.size && (
                <>
                  <FormatSizeIcon color="action" fontSize="small" />
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    style={{ marginLeft: 20 }}
                  >
                    {expedition?.size}
                  </Typography>
                </>
              )}
            </Grid>
            <Grid
              item
              xs={6}
              container
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              {expedition?.weight && (
                <>
                  <FormatSizeIcon color="action" fontSize="small" />
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    style={{ marginLeft: 20 }}
                  >
                    {expedition?.weight}
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
        </CardContent>

        <CardActions disableSpacing>
          <Grid
            container
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid container flexDirection="row" alignItems="center" xs={6}>
              <LocalOfferIcon color="action" fontSize="small" />
              <Typography
                variant="caption"
                color="text.secondary"
                style={{ marginLeft: 20 }}
              >
                {expedition?.price}
              </Typography>
            </Grid>
            <Grid xs={6} container justifyContent="flex-end">
              <IconButton color="inherit">
                <EmojiPeopleIcon />
              </IconButton>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
      {renderMenu}
    </>
  );
}
