import { put, select } from "redux-saga/effects";

import helpers from "helpers";
import config from "../../../../config.json";
import errors from "store/errors";
import pathsActions from "../../actions";
import { batchActions } from "redux-batched-actions";
import selectors from "../../selectors";

export default function* hardDeleteWorker({ meta = {} }) {
  const id = yield select(selectors.selected);

  let { data } = yield helpers.sagas.worker({
    method: "DELETE",
    url: config.endpoints.paths.root,
    data: {id},
    loadingId: meta.id,
  });

  let actions = [];

  if (data?.success) {
    actions.push(pathsActions.removed({ id }));
    actions.push(errors.actions.updated({ isSuccess: true, id: meta.id }));

    yield put(batchActions(actions));
  }
}
