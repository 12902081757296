import { put, select } from "redux-saga/effects";

import actions from "../../actions";
import selectors from "../../selectors";

export default function* getWorker() {
  const filter = yield select(selectors.filter);

  if (
    filter?.phone ||
    filter?.clientId ||
    filter?.username ||
    filter?.email ||
    filter?.identityValue ||
    filter?.clientId
  )  yield put(actions.getOne());
  else yield put(actions.getMany());
}
