import { memoize } from 'proxy-memoize'
import selectOneById from '../oneById'
import helpers from 'helpers'
import clientsSelectors from 'features/clients/selectors'

const detailedOneById = memoize(({ state, id }) => {
	if (!id) return null

	const payment = selectOneById({ state, id })

	if (helpers.validator.isEmptyObject(payment)) return null
	if (!payment.creator || helpers.validator.isEmptyString(payment.creator))
		return null

	const creator = clientsSelectors.detailedOneById({
		state,
		id: payment.creator,
	})

	if (!creator) return null

	let result = {
		...payment,
		creator: {
			id: payment.creator,
			fullName: creator.fullName,
			photo: creator.profile?.photo,
		},
		amount: `${payment.amount}  ${creator.currency}`,
		createdAt: `${helpers.dateTime.getDate(
			payment.createdAt
		)}  ${helpers.dateTime.getTime(payment.createdAt)}`,
		status: helpers.getStatus(payment.status),
	}

	result = {
		...result,
		isApproved: result.status === 'approved',
		isInreview: result.status === 'inreview',
		isRejected: result.status === 'rejected',
	}

	return result
})

export default detailedOneById
