/**
 * @filter_format
 * {
 *  status:
 *      inreview -> has something inreview (profile | identity)
 *      rejected -> has something rejected (profile | identity) & it has nothing inreview
 *      inactive
 *      active
 *  isIdentityInreview: Boolean
 *  isProfileInreview: Boolean
 *  isIdentityValid: Boolean
 *  isProfileValid: Boolean
 *  hasIdentity: Boolean
 *  hasProfile: Boolean
 * }
 */

import types from "../../actionsTypes";

export default function filterUpdated({
  status,
  identityStatus,
  profileStatus,
  isPhoneValid,
  meta = {},
}) {
  let payload = {};

  // status
  if (status) payload["status"] = status;

  // phone
  if (isPhoneValid !== undefined && isPhoneValid !== null)
    payload["isPhoneValid"] = isPhoneValid;

  // identity
  if (identityStatus === "inreview") payload["isIdentityInreview"] = true;
  else {
    if (identityStatus === "accepted") payload["isIdentityValid"] = true;
    else {
      if (identityStatus === "not-provided") payload["hasIdentity"] = false;
      payload["isIdentityValid"] = false;
    }
    payload["isIdentityInreview"] = false;
  }

  // profile
  if (profileStatus === "inreview") payload["isProfileInreview"] = true;
  else {
    if (profileStatus === "accepted") payload["isProfileValid"] = true;
    else {
      if (profileStatus === "not-provided") payload["hasProfile"] = false;
      payload["isProfileValid"] = false;
    }
    payload["isProfileInreview"] = false;
  }

  return {
    type: types.filterUpdated,
    payload,
    meta,
  };
}
