import mockingData from './mockingData'
import helpers from 'helpers'

const emptyState = {
	token: null,
	user: {},
}

export default helpers.initReduxState({
	emptyState,
	mockingState: mockingData,
})
