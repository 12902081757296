import isCin from "./is-cin";
import isMobilePhone from "./is-mobile-phone";
import isEmail from "./is-email";
import isVerificationCode from "./is-verification-code";
import isUsername from "./is-username";
import isPassword from "./is-password";
import isName from "./is-name";
import isCountry from "./is-country";
import isPasswordMatch from "./is-password-match";
import isBirthday from "./is-birthday";
import isObject from "./is-object";
import isEmptyObject from "./is-empty-object";
import isEmptyString from "./is-empty-string";
import isArray from "./is-array";
import isNumber from "./is-number";
import isGreatorThanNow from "./is-greator-than-now";
import isValidCapacity from "./is-valid-capacity";
import isValidWeight from "./is-valid-weight";
import isValidPrice from "./is-valid-price";
import isValidDescription from "./is-valid-description";
import isValidReceiver from "./is-valid-receiver";
import isReason from "./is-reason";
import isEmptyArray from "./isEmptyArray"

export default Object.freeze({
  isCin,
  isMobilePhone,
  isEmail,
  isVerificationCode,
  isUsername,
  isPassword,
  isName,
  isCountry,
  isPasswordMatch,
  isBirthday,
  isObject,
  isEmptyObject,
  isEmptyString,
  isArray,
  isNumber,
  isGreatorThanNow,
  isValidCapacity,
  isValidWeight,
  isValidPrice,
  isValidDescription,
  isValidReceiver,
  isReason,
  isEmptyArray
});
