import { memoize } from "proxy-memoize";
import selectSelected from "../selected";
import selectDetailedOneById from "../detailed-one-by-id";

const detailedSelected = memoize((state) => {
  const selectedId = selectSelected(state);

  return selectDetailedOneById({ state, id: selectedId });
});

export default detailedSelected;
