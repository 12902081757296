import { useState } from "react";
import { Edit as EditIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";

import user from "../constant/user";

export const EditPictureIcon = styled(EditIcon)({
  fontSize: 19,
 
});

const useHeader = () => {
  const [profilePicture, setProfilePicture] = useState(user.profilePicture);

  const handleEditProfilePicture = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (event) => {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          // Set the edited profile picture URL
          setProfilePicture(reader.result);
        };
        reader.readAsDataURL(file);
      }
    };
    input.click();
  };

  return {
    user,
    profilePicture,
    setProfilePicture,
    handleEditProfilePicture,
  };
};

export default useHeader;