import { normalize, schema } from "normalizr";

// user schema
const user = new schema.Entity("users");

const usersListSchema = new schema.Array(user);

export default function normalizer(data) {
  const normalizedData = normalize(data, usersListSchema);
  return {
    clients: normalizedData.entities["users"],
  };
}
