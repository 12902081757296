const RECEIVER = "receiver ::: ";
const RECEIVERS = "receivers ::: ";

export default Object.freeze({
  /**
   * FOR SAGA WATCHERS
   */

  get: `${RECEIVERS}get`,
  getOne: `${RECEIVERS}get/one`,
  getMany: `${RECEIVERS}get/many`,
  changeStatus: `${RECEIVER}change/status`,
  remove: `${RECEIVER}remove`,
  hardDelete: `${RECEIVER}hard/delete`,

  /**
   * FOR REDUCER
   */

  selectedSet: `${RECEIVER}selected/set`,
  merged: `${RECEIVERS}merged`,
  statusChanged: `${RECEIVER}status/changed`,
  removed: `${RECEIVER}removed`,
  filterUpdated: `${RECEIVERS}filter/updated`,
});
