import { useDispatch } from "react-redux";

import Components from "components";

import actions from "../../actions";

export default function RejectExpedition({ open, handleCancel }) {
  const dispatch = useDispatch();

  const handleReject = () => {
    dispatch(actions.changeStatus({ status: "rejected" }));
    handleCancel();
  };

  return (
    <Components.ConfirmAction
      open={open}
      handleCancel={handleCancel}
      handleConfirm={handleReject}
      withReason={true}
    />
  );
}
