/**
 * @type simple
 * @description selects selected receiver's id
 * @params state
 * @returns String | null
 * @detailed_return id
 */

import { memoize } from "proxy-memoize";

export default memoize((state) => state?.receivers?.selected ?? null);
