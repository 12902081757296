import { all } from "redux-saga/effects";

import get from "./get";
import changeStatus from "./change-status";
import remove from "./remove";
import hardDelete from "./hardDelete";

export default function* authSaga() {
  yield all([get(), changeStatus(), remove(), hardDelete()]);
}
